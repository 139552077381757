import React, { useMemo } from 'react'
import type { Toast } from 'react-hot-toast'
import { Hash } from 'viem'
import { BlockExplorerLogo } from 'components/BlockExplorerLogo'
import { ButtonLink } from 'components/Button'
import { Chain } from 'utils/chains'
import { formatTokenAmount } from 'utils/web3/helpers'
import CustomToast from './CustomToast'

type Props = {
  destinationChain: Chain
  transactionChain: Chain
  tx: Hash
  newBalance: bigint
  toast: Toast
}

function BridgeConfirmedToast({
  destinationChain,
  transactionChain,
  tx,
  newBalance,
  toast,
}: Props) {
  const formattedNewBalance = useMemo(
    () =>
      formatTokenAmount({
        amount: newBalance.toString(),
        showFree: false,
      }),
    [newBalance],
  )

  return (
    <CustomToast toast={toast} fixedWidth showCloseButton>
      <div className="flex flex-col gap-4">
        <div>
          Bridge confirmed! You now have{' '}
          <span className="uppercase">{formattedNewBalance}</span> on{' '}
          {destinationChain.name}.
        </div>
        <div className="flex gap-4">
          <ButtonLink
            variant="raised"
            className="flex-1"
            href={`${transactionChain.blockExplorer.url}/tx/${tx}`}
            target="_blank"
            rel="noopener noreferrer"
            label={`View on ${transactionChain.blockExplorer.name}`}
            icon={
              <BlockExplorerLogo
                chainId={transactionChain.id}
                className="w-[1em] h-[1em]"
              />
            }
          />
        </div>
      </div>
    </CustomToast>
  )
}

export default React.memo(BridgeConfirmedToast)
