import React, { useCallback, useState } from 'react'
import { ProfileSlug } from 'utils/api'
import classNames from 'utils/classnames'
import { lazy } from 'utils/lazy'
import { placeholderBackground } from 'utils/theme'

const BrowserAvatar = lazy({
  loader: () => import('./BrowserAvatar'),
  Fallback: () => null,
})

export type Props = {
  slug: ProfileSlug
  size: number
  onLoaded?: () => void
  transitionIn?: boolean
  className?: string
}

function Avatar({
  slug,
  size,
  onLoaded,
  className,
  transitionIn = false,
}: Props) {
  const [renderReady, renderReadySet] = useState(false)
  const onAvatarLoad = useCallback(() => {
    onLoaded?.()
    renderReadySet(true)
  }, [onLoaded])

  return (
    <div
      style={{ width: size, height: size }}
      className={classNames(
        'aspect-square rounded-full',
        'transition-transform duration-100',
        !transitionIn || renderReady ? 'scale-100' : 'scale-0',
        placeholderBackground,
        className,
      )}
    >
      <BrowserAvatar slug={slug} size={size} onLoaded={onAvatarLoad} />
    </div>
  )
}

export default React.memo(Avatar)
