import { useMemo } from 'react'
// eslint-disable-next-line no-restricted-imports
import { useAccount } from 'wagmi'
import { AddressHash } from './api'

type WalletConnectionState =
  | {
      address: AddressHash
      isConnected: true
    }
  | {
      address: undefined
      isConnected: false
    }

type WalletAccountState = {
  isLoading: boolean
  connectorName?: string
} & WalletConnectionState

const useWalletAccount = (): WalletAccountState => {
  const { address: rawAddress, connector, status, isConnected } = useAccount()

  const isLoading = useMemo(() => {
    if (isConnected) {
      return false
    }
    return status === 'connecting' || status === 'reconnecting'
  }, [isConnected, status])

  if (isConnected) {
    return {
      address: rawAddress as AddressHash,
      isConnected: true,
      connectorName: connector?.name,
      isLoading,
    }
  } else {
    return {
      address: undefined,
      isConnected: false,
      isLoading,
      connectorName: connector?.name,
    }
  }
}

export default useWalletAccount
