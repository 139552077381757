import { withParams } from './helpers'

export type FeedId = 'trending' | 'free' | 'following' | 'allowlist'

export const defaultFeedTabs: FeedId[] = [
  'trending',
  'free',
  'following',
  'allowlist',
]

export const defaultFeed = defaultFeedTabs[0]
export const defaultFeedPath = `/feed/${defaultFeed}`

export interface FeedInfo {
  name: string
  authRequired: boolean
  endpointOverride?: string
  cacheHeader?: string
  showNumbersInList: boolean
  supportsTimeRange: boolean
}

const publicCacheHeader =
  'public max-age=30, stale-while-revalidate=60, stale-if-error=500'

export const feedInfo: Record<FeedId, FeedInfo> = {
  trending: {
    name: 'All',
    authRequired: false,
    cacheHeader: publicCacheHeader,
    showNumbersInList: true,
    supportsTimeRange: true,
  },
  free: {
    name: 'Free',
    authRequired: false,
    cacheHeader: publicCacheHeader,
    showNumbersInList: true,
    supportsTimeRange: true,
  },
  following: {
    name: 'Following',
    authRequired: true,
    showNumbersInList: false,
    supportsTimeRange: false,
  },
  allowlist: {
    name: 'Allowlist',
    authRequired: true,
    showNumbersInList: false,
    supportsTimeRange: false,
  },
}

export const allTimeRanges = ['30m', '1h', '6h', '12h', '24h'] as const
export type FeedTimeRange = typeof allTimeRanges[number]
export const defaultFeedRange: FeedTimeRange = '6h'

export const isTimeRange = (value: unknown): value is FeedTimeRange => {
  return (
    typeof value === 'string' && allTimeRanges.includes(value as FeedTimeRange)
  )
}

export const normalizeQueryValue = (
  value: string | string[] | undefined,
): string | undefined => (typeof value === 'string' ? value : undefined)

export const feedEndpoint = (
  feedId: FeedId,
  day: string | undefined,
  range: FeedTimeRange | undefined,
  chainId: string | undefined,
) => {
  const info = feedInfo[feedId]
  const path = info.endpointOverride ?? feedId
  const route = `mintfun/feed/${path}` as const
  return withParams(route, { day, range, chain: chainId })
}
