import { Dispatch, useEffect } from 'react'
import { useSignMessage } from 'wagmi'
import { useAccount } from 'utils/useAccount'
import { Action, AuthStep } from '../reducer'

const getMessage = () => {
  const payload = { timestamp: Math.floor(Date.now() / 1000).toString() }
  const message = `mint.fun uses this cryptographic signature to verify your ownership of this wallet. Timestamp: ${payload.timestamp}`

  return { message, payload }
}

const useSignAuthMessage = (dispatch: Dispatch<Action>, authStep: AuthStep) => {
  const isSigningMessage = authStep === 'signingMessage'

  const { address, connectorName } = useAccount()
  const { signMessageAsync } = useSignMessage()

  useEffect(() => {
    if (!isSigningMessage || !address) {
      return
    }

    const { message, payload } = getMessage()

    signMessageAsync({ message })
      .then((signedMessageRes) => {
        dispatch({
          type: 'setSignedMessage',
          data: {
            signature: signedMessageRes,
            provider: connectorName ?? 'unknown-provider',
            address,
            message,
            payload,
          },
        })
        dispatch({
          type: 'setStep',
          data: 'loggingIn',
        })
      })
      .catch(() => {
        dispatch({
          type: 'setStep',
          data: 'signMessage',
        })
      })
  }, [isSigningMessage, dispatch, address, connectorName, signMessageAsync])
}

export default useSignAuthMessage
