import qs from 'qs'

export const xPostUrlRegex = /https:\/\/(twitter\.com|x\.com)\/\w+\/status\/\d+/

// Ref: https://developer.x.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent
export function getXPostUrl(
  text: string,
  url?: string,
  relatedXHandle?: string,
) {
  const query: Record<string, string> = { text }
  if (url !== undefined) {
    query.url = url
  }
  if (relatedXHandle !== undefined) {
    query.related = relatedXHandle
  }
  const xIntentQueryString = qs.stringify(query)
  return `https://x.com/intent/tweet?${xIntentQueryString}`
}
