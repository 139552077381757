import { captureException } from '@sentry/nextjs'
import { GetServerSidePropsContext, NextPageContext } from 'next'
import { client, clientRetry } from './client'
import { contractEndpoint, profileEndpoint } from './endpoints'
import {
  Account,
  AddressHash,
  ChainId,
  Checkpoint,
  CollectionResponse,
  Contract,
  MintSource,
  ProfileResponse,
  ProfileSlug,
  ReportOption,
  SubmitTxSuggestionRequestData,
  UpdateProjectSettingsOptions,
} from './types'

export * from './helpers'
export * from './client'
export * from './endpoints'
export * from './hooks'
export * from './types'

export const submitTx = async (
  address: AddressHash,
  hash: string,
  chainId: ChainId,
  isAllowlist: boolean,
  source: MintSource,
) => {
  try {
    await clientRetry('POST', 'mintfun/submit-tx', {
      address,
      hash,
      isAllowlist,
      chainId,
      source,
    })
  } catch {
    // ignore
  }
}

export const getCollection = async (contract: Contract) => {
  try {
    const res = await clientRetry('GET', contractEndpoint({ contract }))
    if (res.ok) {
      return (await res.json()) as CollectionResponse
    }
    return undefined
  } catch {
    return undefined
  }
}

export const getProfile = async (slug: ProfileSlug) => {
  try {
    const res = await clientRetry('GET', profileEndpoint({ slug }))
    if (res.ok) {
      return (await res.json()) as ProfileResponse
    }
    return undefined
  } catch {
    return undefined
  }
}

export async function getAccount(
  ctx?: GetServerSidePropsContext | NextPageContext,
): Promise<Account | undefined> {
  const res = await clientRetry('GET', 'mintfun/account', null, ctx)
  if (!res.ok) {
    return undefined
  }
  const account: Account = await res.json()
  return account
}

export const submitReport = async (
  contract: Contract,
  address: AddressHash,
  reasons: Array<ReportOption>,
  body?: string,
) => {
  const res = await clientRetry('POST', 'mintfun/report', {
    address,
    reasons,
    contract,
    body: body !== undefined && body.trim().length ? body : undefined,
  })

  if (!res.ok) {
    return { success: false }
  }

  const json: { success: boolean } = await res.json()

  return json
}

export const submitTxSuggestion = async (
  data: SubmitTxSuggestionRequestData,
) => {
  const response = await fetch('/api/mintfun/tx-suggestion', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    throw new Error('Failed to submit tx suggestion')
  }
}

export const updateProjectSettings = async (
  data: UpdateProjectSettingsOptions,
) => {
  const response = await fetch('/api/mintfun/project-settings', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    throw new Error('Failed to submit project settings')
  }

  if (response.status !== 200) {
    const json = await response.json()
    throw new Error(json.message)
  }

  return response
}

export const followSlug = async (slug: ProfileSlug, follow: boolean) => {
  const response = await fetch('/api/mintfun/follow', {
    method: follow ? 'POST' : 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ slug }),
  })

  if (!response.ok) {
    throw new Error('Failed to follow slug')
  }
}

export const updateCheckpoints = async (
  checkpointId: Checkpoint,
): Promise<boolean> => {
  const res = await client('PUT', `mintfun/account/checkpoints`, {
    checkpointId,
  })

  if (!res.ok) {
    const error = new Error('Failed to update checkpoints')

    // sentry context enhancement
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    error.response = res

    console.warn(error)
    captureException(error, { level: 'warning' })
  }

  return res.ok
}
