// Chromium has a rendering bug when applying opacity to animated elements, where the opacity is applied twice.
// See https://bugs.chromium.org/p/chromium/issues/detail?id=1343653#c5
//
// We work around this by wrapping the icon in <g> and apply <animateTransform> to it.
//
// Once the above bug is fixed, we can remove the <animateTransform>, wrapping <g>, and add `animate-spin` class to the <svg> instead.
import { SVGProps } from 'react'
import classnames from 'utils/classnames'

export const Spinner = (props: Partial<SVGProps<SVGSVGElement>>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
    className={classnames('w-[1.25em] h-[1.25em]', props.className)}
  >
    <g className="origin-center">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        by="360"
        dur="1s"
        repeatCount="indefinite"
      />
    </g>
  </svg>
)
