import { ReactNode, useMemo } from 'react'
import {
  ReservoirKitProvider,
  darkTheme as reservoirDarkTheme,
  lightTheme as reservoirLightTheme,
} from '@reservoir0x/reservoir-kit-ui'
import { useTheme } from 'next-themes'
import { useNetwork } from 'wagmi'
import { reservoirEndpoint } from 'utils/api'
import { defaultChain, supportedChains } from 'utils/chains'

const darkTheme = reservoirDarkTheme({
  font: 'DataFun, monospace',
  borderRadius: '0.375rem',
  primaryColor: '#525252',
  primaryHoverColor: '#a3a3a3',
  contentBackground: '#171717',
  headerBackground: '#171717',
})

// NB: this theme doesn't support changing the hover text color, which should be black
// Reservoir is working on adding this in a future version
const lightTheme = reservoirLightTheme({
  font: 'DataFun, monospace',
  borderRadius: '0.375rem',
  primaryColor: '#000000',
  primaryHoverColor: '#d4d4d4',
  contentBackground: '#ffffff',
  headerBackground: '#ffffff',
  buttonTextHoverColor: '#000000',
})

export default function Provider({ children }: { children: ReactNode }) {
  const { theme: currentThemeString } = useTheme()
  const { chain } = useNetwork()
  const chainId = chain?.id ?? defaultChain.id

  const reservoirTheme = useMemo(() => {
    if (currentThemeString === 'dark') {
      return darkTheme
    }

    return lightTheme
  }, [currentThemeString])

  return (
    <ReservoirKitProvider
      options={{
        chains: supportedChains.map((c) => ({
          id: c.id,
          baseApiUrl:
            typeof window === 'undefined'
              ? ''
              : reservoirEndpoint(window.location.origin, c.id),
          active: c.id === chainId,
        })),
        disablePoweredByReservoir: true,
        source: 'mint.fun',
      }}
      theme={reservoirTheme}
    >
      {children}
    </ReservoirKitProvider>
  )
}
