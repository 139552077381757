import { useMemo } from 'react'
import Link from 'next/link'
import { Variant as BtnVariant } from 'components/Button'
import { Variant as TextInputVariant } from 'components/TextInput'
import {
  PendingEmailStateResponse,
  authEmailStateEndpoint,
  useAPIResponse,
} from 'utils/api'
import { useAccount } from 'utils/useAccount'
import EmailForm from './EmailForm'

type EmailProps = {
  email?: string
  textInputVariant?: TextInputVariant
  btnVariant?: BtnVariant
}

export default function EmailSignup({
  textInputVariant,
  btnVariant,
}: EmailProps) {
  const { account } = useAccount()
  const email = account?.email

  const { data: pendingEmailData } = useAPIResponse<PendingEmailStateResponse>(
    authEmailStateEndpoint,
  )

  const sentAt = pendingEmailData?.sentAt

  const submittedRecently = useMemo(() => {
    if (sentAt !== undefined && sentAt !== null) {
      const lastUpdatedTime = new Date(sentAt).getTime()
      const oneDayInMs = 86400000
      return Date.now() - lastUpdatedTime <= oneDayInMs
    }

    return false
  }, [sentAt])

  // wait for email state request to avoid flash of content
  if (pendingEmailData === undefined) {
    return null
  }

  return (
    <>
      {submittedRecently ? (
        <p>
          Email submitted! Check {email} for a confirmation link to start
          receiving notifications.
        </p>
      ) : (
        <p className="font-bold">
          Enter your email to be notified when a creator adds you to an
          allowlist. This can also be updated in{' '}
          <Link className="underline" href="/settings">
            Settings
          </Link>
          .
        </p>
      )}
      <EmailForm
        submittedRecently={submittedRecently}
        textInputVariant={textInputVariant}
        btnVariant={btnVariant}
      />
    </>
  )
}
