import { lazy } from 'utils/lazy'

export const Modal = lazy({
  loader: async () => (await import('./Modal')).Modal,
  Fallback: ({ props }) => <>{props.trigger}</>,
})

export const ModalClose = lazy({
  loader: async () => (await import('./Modal')).ModalClose,
  Fallback: ({ props }) => <>{props.children}</>,
})

export const ModalContent = lazy({
  loader: async () => (await import('./Modal')).ModalContent,
  Fallback: () => null,
})

export const DialogContent = lazy({
  loader: async () => (await import('./Modal')).DialogContent,
  Fallback: ({ props }) => <>{props.children}</>,
})
