import React from 'react'
import { useBreakpoints } from 'react-match-breakpoints'
import Avatar from 'components/Avatar'
import WalletIcon from 'components/icons/Wallet'
import { AddressHash } from 'utils/api'
import { useHasMounted } from 'utils/react-helpers'
import { truncateAddress } from '../helpers'

type Props = {
  address?: AddressHash
  ensName?: string
  onAccountClick?(): void
  onConnectWalletClick?(): void
}

const WalletButton = ({
  address,
  ensName,
  onAccountClick,
  onConnectWalletClick,
}: Props) => {
  const bp = useBreakpoints()

  const avatarSize = bp.md ? 36 : 20

  const mounted = useHasMounted()

  return (
    <div
      {...(!mounted && {
        'aria-hidden': true,
        style: {
          opacity: 0,
          pointerEvents: 'none',
          userSelect: 'none',
        },
      })}
    >
      {(() => {
        if (!mounted) {
          return <button />
        }

        if (address === undefined) {
          return (
            <button
              onClick={onConnectWalletClick}
              className="flex items-center"
            >
              {bp.md ? 'Connect Wallet' : <WalletIcon />}
            </button>
          )
        }

        return (
          <button onClick={onAccountClick} className="flex items-center gap-2">
            <Avatar size={avatarSize} slug={address} />
            <span className="font-bold text-xs md:text-base truncate hidden md:flex">
              {ensName ?? truncateAddress(address)}
            </span>
          </button>
        )
      })()}
    </div>
  )
}
export default React.memo(WalletButton)
