// eslint-disable-next-line no-restricted-imports
import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

// this should be preferred over the direct clsx library
// because it allows tailwind overrides
// see: https://www.npmjs.com/package/tailwind-merge
const classnames = (...args: Parameters<typeof clsx>) => twMerge(clsx(...args))

export default classnames
