import { lazy } from 'utils/lazy'

const EtherscanLogo = lazy({
  loader: () => import('./EtherscanLogo'),
  Fallback: () => (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" />
  ),
})

export default EtherscanLogo
