import { memo } from 'react'
import { useNetwork } from 'wagmi'
import WalletIcon from 'components/icons/Wallet'
import { useCurrentAddressBalance } from 'utils/chains'
import classnames from 'utils/classnames'
import { formatTokenAmount } from 'utils/web3/helpers'

type Props = {
  open: boolean
  handleButtonClick: () => void
}

function WalletNavButton({ handleButtonClick, open }: Props) {
  const { chain } = useNetwork()
  const balance = useCurrentAddressBalance({ chainId: chain?.id })

  return (
    <button
      onClick={handleButtonClick}
      className={classnames(
        'flex items-center gap-2 py-2 px-3 h-full',
        open && 'bg-neutral-200 dark:bg-neutral-850',
      )}
    >
      <WalletIcon />
      {balance?.value !== undefined && (
        <span className="font-bold text-xs md:text-base truncate hidden md:flex uppercase">
          {formatTokenAmount({
            amount: balance.value,

            // if number is less .01 but greater than .0009, show an extra decimal place
            truncateAmount:
              balance.value <= 9999999999999999n &&
              balance.value > 999999999999999n
                ? 3
                : 2,

            showFree: false,
          })}
        </span>
      )}
    </button>
  )
}

export default memo(WalletNavButton)
