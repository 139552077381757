export function assertUnreachable(
  value: never,
  additionalContext?: string,
): never {
  throw new Error(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `expected never but received ${value}${
      additionalContext !== undefined ? ` (${additionalContext})` : ''
    }`,
  )
}
