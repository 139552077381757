import classNames from 'utils/classnames'

export const background = classNames('bg-background dark:bg-background-dark')
export const modalBackground = classNames('bg-white dark:bg-neutral-900')
export const toastBackground = classNames('bg-white dark:bg-neutral-700')
export const hovercardBackground = classNames('bg-white dark:bg-neutral-700')
export const placeholderBackground = classNames(
  'bg-neutral-300 dark:bg-neutral-600',
)

export const textPrimary = classNames('text-primary dark:text-primary-dark')
export const textSecondary = classNames(
  'text-secondary dark:text-secondary-dark',
)
export const textPrimaryOnHover = classNames(
  'hover:text-primary dark:hover:text-primary-dark',
)

export const bodyStyles = classNames(
  textPrimary,
  'text-base font-body lowercase',
  background,
)
