import { Dispatch, useEffect } from 'react'
import { loginOrRegister } from 'utils/api/auth/utils'
import { createToast } from 'utils/toasts'
import { SignedMessage } from 'utils/web3/types'
import { Action, AuthStep } from '../reducer'

const useLogin = (
  dispatch: Dispatch<Action>,
  authStep: AuthStep,
  signedMessage: SignedMessage | undefined,
) => {
  const isLoggingIn = authStep === 'loggingIn'

  useEffect(() => {
    if (!isLoggingIn || signedMessage === undefined) {
      return
    }

    loginOrRegister(signedMessage).then((res) => {
      dispatch({ type: 'reset' })

      if (!res.success) {
        createToast('Error logging in! Please try again')
      }
    })
  }, [isLoggingIn, signedMessage, dispatch])
}

export default useLogin
