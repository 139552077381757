var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"de00d86a71922d3dd5636270fc39f36c7ea0ff46"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import { captureMessage, flush, init } from '@sentry/nextjs'

// import supportedBrowsersRegex from './utils/supported-browsers'

const ignoredMessages = [
  // this is an ignorable and benign error
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
  /ResizeObserver loop limit exceeded/i,
  // this is the firefox message of the above
  /ResizeObserver loop completed with undelivered notifications/i,
]

const unsupportedBrowserMessage =
  'Unsupported browser detected. Sentry will not report errors'

// iife for async and early returns
;(async function () {
  // TODO: If this is no longer working we should either fix it or remove it
  const isSupportedBrowser = true

  init({
    // Assumes that NEXT_PUBLIC_SENTRY_DSN is only set in production
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: 'production',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.05,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    replaysSessionSampleRate: 0.05,

    normalizeDepth: 10,

    beforeSend(event) {
      if (!isSupportedBrowser && event.message !== unsupportedBrowserMessage) {
        // don't report errors if we have an unsupported browser, but allow the
        // unsupportedBrowserMessage itself
        return null
      }
      if (
        ignoredMessages.some((message) => message.test(event.message ?? ''))
      ) {
        return null
      }
      return event
    },

    integrations: [new ExtraErrorDataIntegration()],

    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })

  if (!isSupportedBrowser) {
    captureMessage(unsupportedBrowserMessage)

    // make sure the above message is sent
    await flush()
  }
})()
