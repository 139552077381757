import {
  EncodeFunctionDataParameters,
  EstimateGasExecutionError,
  Hex,
  InsufficientFundsError,
  TransactionExecutionError,
  UserRejectedRequestError,
  encodeFunctionData,
  formatUnits,
  isAddress,
} from 'viem'
import { ChainMismatchError } from 'wagmi'
import {
  AddressHash,
  Contract,
  ContractTransaction,
  ProfileSlug,
} from 'utils/api'
import { getContractPath } from 'utils/chains'
import { assertUnreachable } from 'utils/typescript'

export const zeroAddress: AddressHash =
  '0x0000000000000000000000000000000000000000' as AddressHash

export const isAddressHash = (address: unknown): address is AddressHash => {
  if (typeof address !== 'string') {
    return false
  }
  return isAddress(address)
}

export const makeAddressHash = (address: string) => {
  if (isAddressHash(address)) {
    return address
  }
  throw new Error(`Invalid address: ${address}`)
}

export const isContract = (contract: unknown): contract is Contract => {
  if (typeof contract !== 'string') {
    return false
  }
  const chainIdRegex = /^[0-9]+$/
  const [chainId, address] = contract.split(':')
  if (chainIdRegex.test(chainId) && isAddress(address)) {
    return true
  }
  return false
}

export function formatTokenAmount({
  amount: rawAmount,
  truncate = true,
  truncateAmount = 6,
  tokenDecimals = 18,
  tokenSymbol = 'ETH',
  showFree = true,
  conversionRate,
}: {
  amount: bigint | string | number
  truncate?: boolean
  truncateAmount?: number
  tokenDecimals?: number
  tokenSymbol?: string | null
  showFree?: boolean
  conversionRate?: number
}): string {
  let amount: bigint
  if (typeof rawAmount === 'bigint') {
    amount = rawAmount
  } else if (typeof rawAmount === 'string') {
    // temp workaround for bad api data
    // if there is a decimal point, just use the left side of it
    amount = BigInt(rawAmount.split('.')[0])
  } else if (typeof rawAmount === 'number') {
    amount = BigInt(rawAmount)
  } else {
    assertUnreachable(rawAmount, 'formatTokenAmount')
  }

  if (amount === 0n && showFree) {
    return 'FREE'
  }

  const rawFormatted = formatUnits(amount, tokenDecimals)

  let suffix = tokenSymbol !== null ? ` ${tokenSymbol}` : ''
  if (conversionRate !== undefined) {
    suffix = ` ($${(Number(rawFormatted) * conversionRate).toFixed(2)})`
  }

  if (!truncate) {
    return `${rawFormatted}${suffix}`
  }

  // if it doesn't have a decimal place, return the raw value
  if (rawFormatted.indexOf('.') === -1) {
    return `${rawFormatted}${suffix}`
  }

  // if it has a decimal place, truncate it after `truncateAmount` places
  const [whole, decimal] = rawFormatted.split('.')
  const truncatedDecimal = decimal.substring(0, truncateAmount)
  const truncated = `${whole}.${truncatedDecimal}`

  return `${truncated}${suffix}`
}

export const truncateAddress = (address: string) => {
  return address.substring(0, 8).toLowerCase()
}

export const displayNamify = (slug: ProfileSlug) => {
  // if its an address, truncate it
  if (isAddress(slug)) {
    return truncateAddress(slug)
  }
  return slug
}

export const tryBigNumber = (value: string): bigint | undefined => {
  if (value === '') {
    return undefined
  }
  try {
    return BigInt(value)
  } catch {
    return undefined
  }
}

export const isInsufficientFundsGasEstimation = (prepareError: unknown) => {
  if (prepareError === null) {
    return false
  }

  if (prepareError instanceof InsufficientFundsError) {
    return true
  }

  if (prepareError instanceof EstimateGasExecutionError) {
    return prepareError.details.includes(
      'insufficient funds for gas * price + value',
    )
  }

  return false
}

export const isUserRejectedRequestError = (error: unknown) => {
  if (error instanceof UserRejectedRequestError) {
    return true
  }

  if (
    error instanceof TransactionExecutionError &&
    (isMetaMaskUserRejectedRequestError(error) ||
      isRainbowUserRejectedRequestError(error))
  ) {
    return true
  }

  return false
}

const isMetaMaskUserRejectedRequestError = (
  error: TransactionExecutionError,
) => {
  return error.shortMessage.includes('User rejected the request')
}

const isRainbowUserRejectedRequestError = (
  error: TransactionExecutionError,
) => {
  return error.shortMessage.includes('User cancelled the request')
}

export const isMintButtonDisabled = (
  isWrongChain: boolean,
  transaction: ContractTransaction,
  address: AddressHash | undefined,
  prepareError: Error | null,
) => {
  if (!transaction.isValid) {
    return true
  }

  if (isWrongChain) {
    return false
  }

  if (address === undefined) {
    return false
  }

  if (prepareError === null) {
    return false
  }

  return (
    !(prepareError instanceof ChainMismatchError) &&
    !isInsufficientFundsGasEstimation(prepareError) &&
    !(prepareError instanceof InsufficientFundsError)
  )
}

export const shouldIncludeMintButton = (
  isWrongChain: boolean,
  transaction: ContractTransaction,
  address: AddressHash | undefined,
  prepareError: Error | null,
) => {
  return !isMintButtonDisabled(isWrongChain, transaction, address, prepareError)
}

export const encodeFunctionDataWithMintFunAttribution = (
  args: EncodeFunctionDataParameters,
): Hex => {
  return `${encodeFunctionData(args)}0021fb3f`
}

export const getReferralLink = (
  contract: Contract,
  address: AddressHash | undefined,
) => {
  const baseUrl = process.env.NEXT_PUBLIC_WWW_URL
  return baseUrl !== undefined && address !== undefined
    ? `${baseUrl}${getContractPath(contract)}?ref=${address}`
    : ''
}
