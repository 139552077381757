import { memo } from 'react'
import { SearchSuggestion } from 'utils/api'
import { SearchQueryResponse } from 'utils/api'
import SearchResults from './SearchResults'
import SearchSuggestionPill from './SearchSuggestion'

type SearchBodyProps = {
  results: SearchQueryResponse
  suggestions?: Array<SearchSuggestion>
  query?: string
  isLoading?: boolean
  onClose: () => void
}

const SearchBody = ({
  query,
  results,
  suggestions,
  onClose,
}: SearchBodyProps) => {
  const { projects, profiles } = results

  if ((projects === null && profiles === null) || query === undefined) {
    return (
      <div className="mt-9 mb-9">
        <span className="text-xl md:text-search-header font-bold">
          Top projects
        </span>

        <div className="flex flex-row flex-wrap gap-3 mt-6">
          {suggestions?.map(({ name, address }) => (
            <SearchSuggestionPill
              key={`suggestion-${address}`}
              name={name}
              contract={address}
              onSuggestionClick={onClose}
            />
          ))}
        </div>
      </div>
    )
  }

  const noProjects = projects === null || projects.length === 0
  const noProfiles = profiles === null || profiles.length === 0

  if (noProjects && noProfiles) {
    return (
      <div className="mt-9 mb-6">
        <span className="text-xl md:text-2xl font-bold">No results found</span>
      </div>
    )
  }

  return <SearchResults results={results} onClose={onClose} />
}

export default memo(SearchBody)
