import React from 'react'
import { useBreakpoints } from 'react-match-breakpoints'
import Avatar from 'components/Avatar'
import Badge from 'components/Badge'
import { Account, AddressHash, ProfileSlug } from 'utils/api'
import classNames from 'utils/classnames'
import { lazy } from 'utils/lazy'

const ProfileWarning = lazy({
  loader: () => import('components/icons/WarningIcon'),
  Fallback: () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    />
  ),
})

export type MismatchInfo = {
  address: AddressHash
  account: Account
}

type Props = {
  slug: ProfileSlug
  size: number
  warningLocation: 'top-right' | 'bottom-left'
  mismatchInfo?: MismatchInfo
  badgeCount?: number
  className?: string
  transitionIn?: boolean
}

function AvatarWithDecoration({
  slug,
  size,
  warningLocation,
  mismatchInfo,
  badgeCount = 0,
  className,
  transitionIn = false,
}: Props) {
  const { md } = useBreakpoints()

  return (
    <div className={classNames('relative', className)}>
      <Avatar slug={slug} size={size} transitionIn={transitionIn} />
      {badgeCount === 0 && mismatchInfo && (
        <ProfileWarning
          className="absolute"
          width={size * 0.75}
          height={size * 0.75}
          style={{
            top: warningLocation === 'top-right' ? -size / 3 : undefined,
            right: warningLocation === 'top-right' ? -size / 3 : undefined,
            bottom: warningLocation === 'bottom-left' ? -size / 3 : undefined,
            left: warningLocation === 'bottom-left' ? -size / 3 : undefined,
          }}
        />
      )}
      {badgeCount > 0 && (
        <div
          className="absolute"
          style={{
            top: warningLocation === 'top-right' ? (md ? -5 : -3) : undefined,
            right: warningLocation === 'top-right' ? (md ? -5 : -3) : undefined,
            bottom:
              warningLocation === 'bottom-left' ? (md ? -5 : -3) : undefined,
            left:
              warningLocation === 'bottom-left' ? (md ? -5 : -3) : undefined,
          }}
        >
          <Badge className="min-w-2 w-2 h-2" />
        </div>
      )}
    </div>
  )
}

export default React.memo(AvatarWithDecoration)
