import { SVGAttributes } from 'react'

export default function FarcasterIcon(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5.327 3.667c0-.368.298-.667.667-.667h12.015c.368 0 .666.299.666.667v.894c0 .368.3.667.668.667h1.99c.368 0 .667.298.667.667v1.017c0 .308-.25.557-.557.557a.557.557 0 00-.557.557V9.14c0 .308-.25.557-.557.557a.557.557 0 00-.557.557v5.549c0 .307.25.557.557.557.308 0 .557.25.557.557v1.1c0 .307.25.556.557.556.308 0 .557.25.557.557v1.018a.667.667 0 01-.667.667h-6.472a.667.667 0 01-.668-.667V19.13c0-.307.25-.556.557-.556.308 0 .557-.25.557-.557v-1.1c0-.308.25-.557.557-.557.308 0 .557-.25.557-.557v-2.22a.557.557 0 00-.557-.557.557.557 0 01-.557-.557v-1.113a.561.561 0 00-.561-.558.554.554 0 01-.555-.55.55.55 0 00-.55-.551h-3.306a.55.55 0 00-.55.55c0 .305-.25.55-.554.55a.56.56 0 00-.56.558v.556h-.002v.558c0 .307-.25.557-.558.557a.557.557 0 00-.556.556v2.22c0 .308.249.558.556.558.308 0 .557.249.557.557v1.1c0 .307.25.556.557.556.308 0 .557.25.557.557v1.018a.667.667 0 01-.667.667H2.667A.667.667 0 012 20.148V19.13c0-.307.25-.556.557-.556.308 0 .557-.25.557-.557v-1.1c0-.308.25-.557.557-.557.307 0 .557-.25.557-.557v-5.55a.557.557 0 00-.557-.556.557.557 0 01-.557-.557.557.557 0 00-.557-.557A.557.557 0 012 8.026V5.895c0-.369.299-.667.667-.667H4.66a.667.667 0 00.667-.667v-.894z"
      ></path>
    </svg>
  )
}
