import { Dispatch, useCallback, useReducer } from 'react'
import {
  createContext,
  useContextSelector,
} from '@fluentui/react-context-selector'
import useLogin from './hooks/useLogin'
import useSignAuthMessage from './hooks/useSignAuthMessage'
import { Action, AuthStep, State, reducer } from './reducer'

const initialState: State = {
  currentStep: 'idle',
  signedMessage: undefined,
}

type ContextValue = {
  state: State
  dispatch: Dispatch<Action>
}

const AuthContext = createContext<ContextValue>(
  undefined as unknown as ContextValue,
)

export const useAuthDispatch = () =>
  useContextSelector(AuthContext, (state) => state.dispatch)

export const useAuthCurrentStep = () =>
  useContextSelector(AuthContext, ({ state }) => state.currentStep)

export const useAuthStepMatches = (step: AuthStep) =>
  useContextSelector(AuthContext, ({ state }) => state.currentStep === step)

export const useAuthSignedMessage = () =>
  useContextSelector(AuthContext, ({ state }) => state.signedMessage)

/** Opens the auth flow. Must be called after a wallet is already connected */
export const useOpenAuthFlow = (): (() => void) => {
  const dispatch = useAuthDispatch()
  return useCallback(() => {
    dispatch({ type: 'setStep', data: 'signMessage' })
  }, [dispatch])
}

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useSignAuthMessage(dispatch, state.currentStep)
  useLogin(dispatch, state.currentStep, state.signedMessage)

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
