import React, { useCallback } from 'react'
import type { Toast } from 'react-hot-toast'
import { Button, ButtonLink } from 'components/Button'
import XIcon from 'components/icons/Social/X'
import { AddressHash, Contract } from 'utils/api'
import { lazy } from 'utils/lazy'
import { toastLibrary } from '.'
import CustomToast from './CustomToast'
import { useHandleXMintClick, useTokenIdString } from './util'

const CoinbaseLogo = lazy({
  loader: () => import('components/icons/CoinbaseLogo'),
  Fallback: ({ props }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  ),
})

type Props = {
  toast: Toast
  tokenIds: string[]
  transactionHash: string
  collectionContract: Contract
  collectionName?: string
  minterAddress?: AddressHash
}

function PartnerMintSuccessToast({
  toast,
  tokenIds,
  collectionContract,
  collectionName,
  minterAddress,
}: Props) {
  const tokenIdString = useTokenIdString(tokenIds)

  const handleXClick = useHandleXMintClick(
    collectionContract,
    collectionName,
    minterAddress,
  )

  const handleViewMintClick = useCallback(async () => {
    const { toast: toastLib } = await toastLibrary()
    toastLib.dismiss(toast.id)
  }, [toast.id])

  return (
    <CustomToast toast={toast} fixedWidth showCloseButton>
      <div className="flex flex-col gap-4 w-full">
        <div>
          {`Your mint of `}
          <span className="normal-case">
            {collectionName !== undefined && collectionName !== ''
              ? `${collectionName} `
              : ''}
            {tokenIdString}
          </span>
          {` was successful!`}
        </div>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
          <ButtonLink
            href="cbwallet://"
            target="_blank"
            onClick={handleViewMintClick}
            variant="raised"
            className="flex-1"
            label="View in Coinbase"
            icon={<CoinbaseLogo className="w-[1em] h-[1em]" />}
          />

          <Button
            variant="raised"
            className="flex-1"
            onClick={handleXClick}
            label="Post mint"
            icon={<XIcon className="w-[1em] h-[1em]" />}
          />
        </div>
      </div>
    </CustomToast>
  )
}

export default React.memo(PartnerMintSuccessToast)
