import { zeroAddress } from 'viem'
import { AddressHash } from './api'

export const xHandle = 'mintdotfun'
export const farcasterHandle = 'mintdotfun'
export const xUrl = `https://x.com/${xHandle}`
export const dmMintFunXUrl =
  'https://x.com/messages/compose?recipient_id=1534658078427078657'
export const walletConnectProjectId =
  process.env.NEXT_PUBLIC_WALLET_CONNECT_ID ??
  'fea75efcf66504d48fe6d1c1e24ead71'
export const supportedChainIds =
  process.env.NEXT_PUBLIC_SUPPORTED_CHAINS !== undefined
    ? (JSON.parse(process.env.NEXT_PUBLIC_SUPPORTED_CHAINS) as number[])
    : [1]

// cost in wei for buying nfts through reservoir
export const referrerFee = 777000000000000

// 1/2 of protocolFee in wei from https://etherscan.io/address/0x00528e0000006f0000dcbe2f0065011d1fcd411a#readContract
export const referralFee = 100000000000000n

export const zeroAddressHash = zeroAddress as AddressHash

export const maxVisibleMaxSupply = 1_000_000_000_000_000n
