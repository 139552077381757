import { useCallback, useEffect, useState } from 'react'
import {
  createContext,
  useContextSelector,
} from '@fluentui/react-context-selector'
import { Hash } from 'viem'
import { getBalance } from 'viem/actions'
import { usePublicClient } from 'wagmi'
import {
  AddressHash,
  clientRetry,
  submitBridgeTransactionEndpoint,
} from 'utils/api'
import { Chain, defaultChain } from 'utils/chains'
import { lazy } from 'utils/lazy'
import {
  createBridgeConfirmedToast,
  createBridgeTransactionPendingToast,
} from 'utils/toasts'

const ModalComponent = lazy({
  loader: () => import('./Component'),
  Fallback: () => null,
})

type ContextValue = {
  openModal: (targetChain?: Chain) => void
}

const BridgingModalContext = createContext<ContextValue>(
  undefined as unknown as ContextValue,
)

export const useOpenBridgingModal = () =>
  useContextSelector(BridgingModalContext, ({ openModal }) => openModal)

function WaitForTransaction({
  address,
  tx,
  chain,
  markAsComplete,
}: {
  address: AddressHash
  tx: Hash
  chain: Chain
  markAsComplete: (tx: Hash) => void
}) {
  const provider = usePublicClient({ chainId: chain.id })

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    const work = async () => {
      const initialBalance = await getBalance(provider, { address })
      const toastId = await createBridgeTransactionPendingToast(
        tx,
        defaultChain.id,
        chain.id,
      )

      intervalId = setInterval(async () => {
        try {
          const balance = await getBalance(provider, { address })

          if (balance > initialBalance) {
            if (intervalId !== null) {
              clearInterval(intervalId)
            }
            markAsComplete(tx)
            createBridgeConfirmedToast(
              tx,
              chain,
              defaultChain,
              balance,
              toastId,
            )
          }
        } catch (e) {
          console.error('getBalance error', e)
          if (intervalId !== null) {
            clearInterval(intervalId)
          }
        }
      }, 2000)
    }

    work()

    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId)
      }
    }
  }, [address, chain, markAsComplete, provider, tx])

  return null
}

export type SubmitBridgeTransactionFunc = ({
  address,
  tx,
  chain,
  amount,
}: {
  address: AddressHash
  tx: Hash
  chain: Chain
  amount: bigint
}) => void

type Props = {
  children: React.ReactNode
}

export default function BridgingModalProvider({ children }: Props) {
  const [targetChain, setTargetChain] = useState<Chain>()
  const [open, openSet] = useState(false)
  const openModal = useCallback((targetChain?: Chain) => {
    setTargetChain(targetChain)
    openSet(true)
  }, [])

  const onOpenChange = useCallback((newOpen: boolean) => {
    openSet(newOpen)
  }, [])

  const [txs, txsSet] = useState<
    { address: AddressHash; tx: Hash; chain: Chain }[]
  >([])

  const submittedBridgeTransaction = useCallback<SubmitBridgeTransactionFunc>(
    ({ address, tx, chain, amount }) => {
      openSet(false)
      txsSet((txs) => [...txs, { address, tx, chain }])

      try {
        // tx tracking for debugging
        clientRetry('POST', submitBridgeTransactionEndpoint, {
          address,
          tx,
          chainId: chain.id,
          amount: amount.toString(),
        })
      } catch (e) {
        console.error('submitBridgeTransaction error', e)
      }
    },
    [],
  )

  const markAsComplete = useCallback((tx: Hash) => {
    txsSet((txs) => txs.filter((t) => t.tx !== tx))
  }, [])

  return (
    <BridgingModalContext.Provider value={{ openModal }}>
      {children}

      {txs.map(({ address, tx, chain }) => (
        <WaitForTransaction
          key={tx}
          address={address}
          tx={tx}
          chain={chain}
          markAsComplete={markAsComplete}
        />
      ))}
      <ModalComponent
        open={open}
        onOpenChange={onOpenChange}
        submittedBridgeTransaction={submittedBridgeTransaction}
        targetChain={targetChain}
      />
    </BridgingModalContext.Provider>
  )
}
