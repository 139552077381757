import qs from 'qs'

export function getComposeUrl(text: string, url: string) {
  const query: Record<string, string> = {
    text,
    'embeds[]': url,
  }

  const xIntentQueryString = qs.stringify(query)
  return `https://warpcast.com/~/compose?${xIntentQueryString}`
}
