/** Pluralization helper - to be replaced by i18n library at some point */
export const s = (n: number | bigint) =>
  (typeof n === 'bigint' && n === 1n) || (typeof n === 'number' && n === 1)
    ? ''
    : 's'

export const shortenKM = (n: number) => {
  if (n < 1e3) return String(n)
  if (n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  return +(n / 1e6).toFixed(1) + 'M'
}
