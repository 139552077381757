import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useBreakpoints } from 'react-match-breakpoints'
import Search from 'components/icons/Search'
import XMark from 'components/icons/XMark'
import {
  SearchQueryResponse,
  SearchSuggestion,
  searchEndpoint,
  useAPIResponse,
} from 'utils/api'
import classNames from 'utils/classnames'
import { modalBackground, textPrimary, textSecondary } from 'utils/theme'
import SearchBody from './SearchBody'

type SearchModalProps = {
  suggestions?: Array<SearchSuggestion>
  onClose: () => void
}

const ICON_SIZE = {
  default: 24,
  responsive: 16,
}

const SearchModal = ({ suggestions, onClose }: SearchModalProps) => {
  const bp = useBreakpoints()

  const [searchInputValue, searchInputValueSet] = useState('')
  const [searchingValue, searchingValueSet] = useState('')

  const searchInputRef = useRef<HTMLInputElement>(null)

  const skipFetching = !searchingValue.trim()

  const { data, isLoading } = useAPIResponse<SearchQueryResponse>(
    searchEndpoint({ query: searchingValue }),
    undefined,
    { skipFetching, keepPreviousData: true },
  )

  const [results, resultsSet] = useState<SearchQueryResponse>({
    projects: null,
    profiles: null,
  })

  useEffect(() => {
    if (isLoading || skipFetching) {
      return
    }

    resultsSet({
      projects: data?.projects ?? null,
      profiles: data?.profiles ?? null,
    })
  }, [data, isLoading, skipFetching])

  // debounce search requests
  useEffect(() => {
    const timeout = setTimeout(() => {
      searchingValueSet(searchInputValue)
    }, 200)

    return () => clearTimeout(timeout)
  }, [searchInputValue])

  const onFocusInput = useCallback(() => searchInputRef.current?.focus(), [])

  // focus input on search modal open
  useEffect(() => {
    onFocusInput()
  }, [onFocusInput])

  const onSearchInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      searchInputValueSet(e.target.value)

      if (e.target.value === '') {
        resultsSet({
          projects: null,
          profiles: null,
        })
      }
    },
    [],
  )

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const onSearchInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') onClose()

      if (e.key === 'ArrowUp') e.preventDefault()
      if (e.key === 'ArrowDown') e.preventDefault()
    },
    [onClose],
  )

  const onInputClear = useCallback(() => searchInputValueSet(''), [])

  const onStopPropagation = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation(),
    [],
  )

  const iconSize = bp.md ? ICON_SIZE.default : ICON_SIZE.responsive

  return (
    <div className="fixed inset-0 bg-black/50 block z-modal">
      <div
        className="flex flex-1 justify-center items-start h-screen md:mt-14 md:p-11"
        onClick={onClose}
      >
        <div
          className={classNames(
            'flex flex-1 flex-col w-full max-w-4xl h-full md:h-auto',
            'px-5 pt-6 pb-10 md:p-11 md:pb-0',
            'md:rounded-3xl',
            modalBackground,
          )}
          onClick={onStopPropagation}
        >
          <div
            className={classNames(
              modalBackground,
              'flex px-4 md:px-8 md:h-24 items-center rounded-xl border-2 border-neutral-600',
            )}
            onClick={onFocusInput}
          >
            <div className="flex flex-1 flex-row gap-3 md:gap-4 h-16 items-center overflow-hidden">
              <Search height={iconSize} width={iconSize} />

              <input
                ref={searchInputRef}
                onKeyDown={onSearchInputKeyDown}
                className={classNames(
                  'flex flex-1 outline-none pb-0.5',
                  modalBackground,
                  'text-sm md:text-search-input font-bold',
                  textPrimary,
                  'placeholder-secondary dark:placeholder-secondary-dark',
                  'normal-case placeholder:lowercase',
                )}
                placeholder="Search project name or address"
                onChange={onSearchInputChange}
                value={searchInputValue}
              />
            </div>

            {searchInputValue.length > 0 ? (
              <button onClick={onInputClear}>
                <XMark height={iconSize} width={iconSize} />
              </button>
            ) : null}
          </div>

          <SearchBody
            query={searchInputValue}
            results={results}
            suggestions={suggestions}
            onClose={onClose}
          />

          <div
            className="absolute inset-x-0 flex md:hidden"
            style={{ bottom: 'env(safe-area-inset-bottom)' }}
          >
            <button
              onClick={onClose}
              className={classNames(
                textSecondary,
                'flex flex-1 justify-center text-sm mb-6',
              )}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(SearchModal)
