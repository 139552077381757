import { useCallback } from 'react'
import { Button } from 'components/Button'
import { Modal, ModalContent } from 'components/Modal'
import { useAuthCurrentStep, useAuthDispatch } from 'providers/Auth'

const AuthModal = () => {
  const dispatch = useAuthDispatch()
  const currentStep = useAuthCurrentStep()

  const onClose = useCallback(() => {
    dispatch({ type: 'reset' })
  }, [dispatch])

  const onPrimaryClick = useCallback(() => {
    if (currentStep !== 'signMessage') {
      return
    }

    dispatch({
      type: 'setStep',
      data: 'signingMessage',
    })
  }, [currentStep, dispatch])

  return (
    <Modal open={currentStep !== 'idle'} onClose={onClose}>
      <ModalContent
        title="Sign a message to verify your wallet"
        description="This signature will only verify your ownership of your wallet"
        content={
          <Button
            label={
              currentStep === 'signMessage'
                ? 'Sign message'
                : 'Waiting for signature...'
            }
            disabled={currentStep !== 'signMessage'}
            className="w-full"
            onClick={onPrimaryClick}
          />
        }
      />
    </Modal>
  )
}

export default AuthModal
