import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import * as viemChains from 'viem/chains'
import { useBalance, useNetwork, useSwitchNetwork } from 'wagmi'
import {
  AddressHash,
  ChainId,
  CollectionURL,
  Contract,
  getAddress,
  getChainId,
} from './api'
import { supportedChainIds } from './constants'
import { useAccount } from './useAccount'

type ContractLink = {
  name: string
  makeUrl: (address: AddressHash) => string
}

export type Chain = {
  alias: string
  name: string
  /** https://eips.ethereum.org/EIPS/eip-3770 */
  shortName: string
  id: ChainId
  isTestnet: boolean
  bridgeAddress?: AddressHash
  blockExplorer: {
    host: 'etherscan' | 'blockscout'
    name: 'Etherscan' | 'Blockscout' | 'Basescan'
    url: string
  }
  contractLinks: ReadonlyArray<ContractLink>
  supportsSecondaryMarket: boolean
  supportsCreate: boolean
}

const ethereum: Chain = {
  alias: 'ethereum',
  name: 'Ethereum',
  shortName: 'eth',
  id: 1,
  isTestnet: false,
  blockExplorer: {
    host: 'etherscan',
    name: 'Etherscan',
    url: 'https://etherscan.io',
  },
  contractLinks: [
    {
      name: 'OpenSea',
      makeUrl: (address) => {
        return `https://opensea.io/assets/ethereum/${address}`
      },
    },
    {
      name: 'Blur',
      makeUrl: (address) => {
        return `https://blur.io/collection/${address}`
      },
    },
    {
      name: 'Etherscan',
      makeUrl: (address) => {
        return `https://etherscan.io/address/${address}`
      },
    },
  ],
  supportsSecondaryMarket: true,
  supportsCreate: true,
}

export const goerli: Chain = {
  alias: 'goerli',
  name: 'Goerli',
  shortName: 'gor',
  id: 5,
  isTestnet: true,
  blockExplorer: {
    host: 'etherscan',
    name: 'Etherscan',
    url: 'https://goerli.etherscan.io',
  },
  contractLinks: [
    {
      name: 'OpenSea',
      makeUrl: (address) => {
        return `https://testnets.opensea.io/assets/goerli/${address}`
      },
    },
    {
      name: 'Etherscan',
      makeUrl: (address) => {
        return `https://goerli.etherscan.io/address/${address}`
      },
    },
  ],
  supportsSecondaryMarket: true,
  supportsCreate: true,
}

const op: Chain = {
  alias: 'op',
  name: 'OP',
  shortName: 'oeth',
  id: 10,
  isTestnet: false,
  bridgeAddress: '0x99C9fc46f92E8a1c0deC1b1747d010903E884bE1' as AddressHash,
  blockExplorer: {
    host: 'etherscan',
    name: 'Etherscan',
    url: 'https://optimistic.etherscan.io',
  },
  contractLinks: [
    {
      name: 'OpenSea',
      makeUrl: (address) => {
        return `https://opensea.io/assets/optimism/${address}`
      },
    },
    {
      name: 'Etherscan',
      makeUrl: (address) => {
        return `https://optimistic.etherscan.io/address/${address}`
      },
    },
  ],
  supportsSecondaryMarket: true,
  supportsCreate: true,
}

const opGoerli: Chain = {
  alias: 'op-goerli',
  name: 'OP Goerli',
  shortName: 'ogor',
  id: 420,
  isTestnet: true,
  bridgeAddress: '0x636Af16bf2f682dD3109e60102b8E1A089FedAa8' as AddressHash,
  blockExplorer: {
    host: 'etherscan',
    name: 'Etherscan',
    url: 'https://goerli-optimism.etherscan.io',
  },
  contractLinks: [
    {
      name: 'Etherscan',
      makeUrl: (address) => {
        return `https://goerli-optimism.etherscan.io/address/${address}`
      },
    },
  ],
  supportsSecondaryMarket: false,
  supportsCreate: true,
}

const zora: Chain = {
  alias: 'zora',
  name: 'Zora',
  shortName: 'zora',
  id: 7777777,
  isTestnet: false,
  bridgeAddress: '0x1a0ad011913A150f69f6A19DF447A0CfD9551054' as AddressHash,
  blockExplorer: {
    host: 'blockscout',
    name: 'Blockscout',
    url: 'https://explorer.zora.energy',
  },
  contractLinks: [
    {
      name: 'OpenSea',
      makeUrl: (address) => {
        return `https://opensea.io/assets/zora/${address}`
      },
    },
    {
      name: 'Blockscout',
      makeUrl: (address) => {
        return `https://explorer.zora.energy/address/${address}`
      },
    },
  ],
  supportsSecondaryMarket: false,
  supportsCreate: true,
}

export const base: Chain = {
  alias: 'base',
  name: 'Base',
  shortName: 'base',
  id: 8453,
  isTestnet: false,
  bridgeAddress: '0x49048044D57e1C92A77f79988d21Fa8fAF74E97e' as AddressHash,
  blockExplorer: {
    host: 'etherscan',
    name: 'Basescan',
    url: 'https://basescan.org',
  },
  contractLinks: [
    {
      name: 'OpenSea',
      makeUrl: (address) => {
        return `https://opensea.io/assets/base/${address}`
      },
    },
    {
      name: 'Basescan',
      makeUrl: (address) => {
        return `https://basescan.org/address/${address}`
      },
    },
  ],
  supportsSecondaryMarket: true,
  supportsCreate: true,
}

const baseGoerli: Chain = {
  alias: 'base-goerli',
  name: 'Base Goerli',
  shortName: 'basegor',
  id: 84531,
  isTestnet: true,
  bridgeAddress: '0xe93c8cd0d409341205a592f8c4ac1a5fe5585cfa' as AddressHash,
  blockExplorer: {
    host: 'etherscan',
    name: 'Etherscan',
    url: 'https://goerli.basescan.org',
  },
  contractLinks: [
    {
      name: 'Etherscan',
      makeUrl: (address) => {
        return `https://goerli.basescan.org/address/${address}`
      },
    },
  ],
  supportsSecondaryMarket: true,
  supportsCreate: false,
}

const allChains = [ethereum, goerli, op, opGoerli, base, baseGoerli, zora]

export const supportedChains: ReadonlyArray<Chain> = allChains.filter((chain) =>
  supportedChainIds.includes(chain.id),
)

export const supportedBridgableChains = supportedChains.filter(
  (chain) => chain.bridgeAddress !== undefined,
)

export const supportedCreateChains = supportedChains.filter(
  (chain) => chain.supportsCreate,
)

export const isSupportedBridgableChain = (chainId: ChainId) =>
  supportedBridgableChains.some((chain) => chain.id === chainId)

export const defaultChain = supportedChains[0]

export const isSupportedChainId = (chainId: unknown): chainId is ChainId => {
  return supportedChains.some((chain) => chain.id === chainId)
}

export const isTestnet = (chainId: ChainId) => {
  const chain = getChainById(chainId)
  return chain ? chain.isTestnet : false
}

export const getChainByShortName = (shortName: string): Chain | undefined => {
  return supportedChains.find((chain) => chain.shortName === shortName)
}

export const getChainById = (id: number): Chain | undefined => {
  return supportedChains.find((chain) => chain.id === id)
}

export const getChainByIdWithDefault = (id: number): Chain => {
  return getChainById(id) || defaultChain
}

export const getChainByAlias = (
  alias: string | undefined,
): Chain | undefined => {
  return supportedChains.find((chain) => chain.alias === alias)
}

export const getContractPath = (contract: Contract): string => {
  const chainId = getChainId(contract)
  const chain = getChainByIdWithDefault(chainId)
  return `/${chain.alias}/${getAddress(contract)}`
}

export const getChainByContract = (contract: Contract): Chain | undefined => {
  const chainId = getChainId(contract)
  return getChainById(chainId)
}

export const getChainIdString = (chainId: ChainId): `${ChainId}` => {
  return `${chainId}` as `${ChainId}`
}

export const getChainIdFromChainIdString = (
  chainIdString: `${ChainId}`,
): ChainId => {
  return parseInt(chainIdString, 10) as ChainId
}

export const sortChainsById = (_chains: ReadonlyArray<Chain>): Chain[] => {
  const chains = [..._chains]
  return chains.sort((a, b) => a.id - b.id)
}

export const getCollectionURLs = (contract: Contract): CollectionURL[] => {
  const address = getAddress(contract)
  const chainId = getChainId(contract)
  const chain = getChainById(chainId)

  return chain !== undefined
    ? chain.contractLinks.map((contractLink) => ({
        label: contractLink.name,
        url: contractLink.makeUrl(address),
      }))
    : []
}

export const getBlockExplorer = (contract: Contract) => {
  const chainId = getChainId(contract)
  const chain = getChainByIdWithDefault(chainId)
  return chain.blockExplorer
}

export const getBlockExplorerUrl = (contract: Contract) => {
  return getBlockExplorer(contract).url
}

export const useCurrentAddressBalance = (
  vals: Parameters<typeof useBalance>[0],
) => {
  const { address } = useAccount()
  const { data: balance } = useBalance({
    address,
    ...vals,
  })
  return balance
}

export const useOpenCorrectNetwork = (address: Contract) => {
  const { chain } = useNetwork()
  const collectionChain = getChainByContract(address)
  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: collectionChain?.id,
  })
  const isCorrectChain = chain?.id === collectionChain?.id

  const openState = useState(false)
  const [waitingOnRightNetwork, waitingOnRightNetworkSet] = useState(false)

  const switchNetworkAndOpen = useCallback(async () => {
    if (switchNetworkAsync === undefined) {
      return
    }

    try {
      await switchNetworkAsync()
      waitingOnRightNetworkSet(true)
    } catch (e) {
      //
    }
  }, [switchNetworkAsync])

  useEffect(() => {
    if (waitingOnRightNetwork && isCorrectChain) {
      waitingOnRightNetworkSet(false)
      setTimeout(() => {
        openState[1](true)
      }, 100)
    }
  }, [openState, isCorrectChain, waitingOnRightNetwork])

  return {
    isCorrectChain,
    switchNetworkAndOpen,
    openState,
  }
}

export const useChainFromRouterOrWallet = () => {
  const router = useRouter()
  const { chain } = useNetwork()

  const routerChain = useMemo(() => {
    if (router.query.chain === undefined) {
      return defaultChain
    }

    const chain = getChainByAlias(router.query.chain.toString())
    if (chain === undefined) {
      return defaultChain
    }

    return chain
  }, [router.query.chain])

  // use router query if exists, otherwise use chain from connected network or default
  const walletChain = getChainById(chain?.id ?? defaultChain.id)
  return routerChain ?? walletChain
}

export const getWagmiChainById = (chainId: ChainId) => {
  switch (chainId) {
    case 1:
      return viemChains.mainnet
    case 5:
      return viemChains.goerli
    case 10:
      return viemChains.optimism
    case 8453:
      return viemChains.base
    case 7777777:
      return viemChains.zora
    case 420:
      return viemChains.optimismGoerli
    default:
      return null
  }
}
