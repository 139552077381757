import { useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import nProgress from 'nprogress'

export default function useNProgress() {
  const router = useRouter()

  const progressTimeoutRef = useRef<number | undefined>(undefined)

  const handleStart = useCallback((_url, _opts: { shallow: boolean }) => {
    // since we have the delay, we don't need to exclude shallow routes that are nearly instant

    if (progressTimeoutRef.current !== undefined) {
      clearTimeout(progressTimeoutRef.current)
    }

    progressTimeoutRef.current = window.setTimeout(() => {
      nProgress.start()
    }, 100)
  }, [])

  const handleStop = useCallback(() => {
    if (progressTimeoutRef.current !== undefined) {
      clearTimeout(progressTimeoutRef.current)
    }

    nProgress.done()
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [handleStart, handleStop, router])
}
