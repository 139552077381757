import { Reducer } from 'react'
import { SignedMessage } from 'utils/web3/types'

export type AuthStep = 'idle' | 'signMessage' | 'signingMessage' | 'loggingIn'

export type State = {
  currentStep: AuthStep
  signedMessage?: SignedMessage
}

export type Action =
  | {
      type: 'setStep'
      data: AuthStep
    }
  | {
      type: 'reset'
      data?: never
    }
  | {
      type: 'setSignedMessage'
      data: SignedMessage | undefined
    }

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action,
) => {
  const actionType = action.type
  switch (actionType) {
    case 'setStep':
      return {
        ...state,
        currentStep: action.data,
      }
    case 'setSignedMessage':
      return {
        ...state,
        signedMessage: action.data,
      }
    case 'reset':
      return {
        currentStep: 'idle',
        signedMessage: undefined,
      }
    default:
      console.error(
        'Unknown action type:',
        (action as Record<string, unknown>).type,
      )
      return { ...state }
  }
}
