import React, { HTMLInputTypeAttribute, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'
import classNames from 'utils/classnames'

type Props = {
  id?: string
  value?: string
  placeholder?: string
  disabled?: boolean
  onChange?: (value: string) => void
  className?: string
  maxLength?: number
  variant?: Variant
  type?: HTMLInputTypeAttribute
}

const sharedClassNames = 'bg-neutral-200 dark:bg-neutral-800'

const variants = {
  primary: sharedClassNames,
  raised: twMerge(sharedClassNames, 'dark:bg-neutral-700'),
}

export type Variant = keyof typeof variants

function TextInput({
  id,
  value,
  placeholder,
  disabled = false,
  onChange,
  className,
  type = 'text',
  variant = 'primary',
}: Props) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => onChange?.(event.target.value),
    [onChange],
  )

  const classes = classNames(
    'rounded-md py-2 px-3 placeholder-secondary dark:placeholder-secondary-dark',
    'outline-neutral-600',
    'disabled:opacity-50 normal-case placeholder:lowercase',
    twMerge(variants[variant], className),
  )

  return (
    <input
      id={id}
      type={type}
      className={classes}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      disabled={disabled}
      autoComplete="off"
    />
  )
}

export default React.memo(TextInput)
