import { ReactNode, memo, useMemo } from 'react'
import { createPortal } from 'react-dom'

export const backgroundId = 'mf-background'

function Container() {
  return <div id={backgroundId} className="fixed inset-0 -z-10" />
}

function Portal({ children }: { children: ReactNode }) {
  const backgroundEl = useMemo(() => document.getElementById(backgroundId), [])
  if (backgroundEl === null) return null

  return createPortal(children, backgroundEl)
}

export const Background = memo(Container)
export const BackgroundPortal = memo(Portal)
