import { useContextSelector } from '@fluentui/react-context-selector'
import { LayoutContext } from 'providers/Layout'

export const useSidebarOpen = () =>
  useContextSelector(LayoutContext, (state) => state.sidebarOpen)

export const useSidebarToggle = () =>
  useContextSelector(LayoutContext, (state) => state.toggleSidebar)

export const useCloseSidebar = () =>
  useContextSelector(LayoutContext, (state) => state.closeSidebar)

export const useSettingsOverride = () =>
  useContextSelector(LayoutContext, (state) => state.settingsOverride)

export const useSetSettingsOverride = () =>
  useContextSelector(LayoutContext, (state) => state.setSettingsOverride)
