import React from 'react'
import { Types } from 'connectkit'
import Avatar from 'components/Avatar'
import { AddressHash, ProfileSlug } from 'utils/api'

const CustomAvatar = ({ address, size }: Types.CustomAvatarProps) => (
  <Avatar
    slug={(address as AddressHash | undefined) ?? ('' as ProfileSlug)}
    size={size}
  />
)

export default CustomAvatar
