import { Brand } from 'utility-types'
import { Hex } from 'viem'
import { FeedId } from './feeds'
import { ProjectTheme } from './themes'

export * from './feeds'
export * from './themes'

export type AddressHash = Brand<`0x${string}`, 'AddressHash'>
export type AccountId = Brand<string, 'AccountId'>
export type ChainId = 1 | 5 | 10 | 420 | 8453 | 84531 | 7777777
export type Contract = Brand<`${ChainId}:${AddressHash}`, 'Contract'>
export type _ProfileSlug = Brand<string, 'ProfileSlug'>
export type ProfileSlug = AddressHash | _ProfileSlug

export type ProfileId = Brand<string, 'ProfileId'>

export type MintSource =
  | 'projectPage'
  | 'featured'
  | `feed:${FeedId}`
  | 'fundrop-reward'
  | 'teams-winner'
  | 'teams-commemorative'

export type HexColor = `#${string}`

export interface CollectionFeedResponse {
  collections: Collection[]
}

export interface CollectionResponse {
  collection: Collection
  profiles: Profile[]
}

export interface Collection {
  contract: Contract
  name: string
  imageUrl?: string
  headerImageUrl?: string
  headerImageWidth?: number
  headerImageHeight?: number
  mintsLastHour: `${bigint}`
  addressesLastHour: string
  lastEvent: string // ISO8601 date
  firstEvent: string // ISO8601 date
  nextMintEnd?: string // ISO8601 date
  totalMints: string
  deployer: ProfileId | null | undefined
  maxSupply?: string
  allowlist: ContractAllowlistState
  flags?: CollectionFlag[]
  mintHeat?: number
  /** NOTE: Use `useCollectionSettings` instead, unless you are certain you want the raw value */
  settings: CollectionSettings
  isFunContract: boolean

  contractKind?: 'ERC721' | 'ERC1155'
}

export interface CollectionURL {
  url: string
  label: string
}

export interface CollectionDetails {
  topMinters: CollectionMinter[]
  minterCount: `${bigint}`
  totalTxFees: `${bigint}`
  avgGas: `${bigint}`
  totalValue: `${bigint}`
  additionalUrls: CollectionURL[]
  mediaSource?: string
  metadataSource?: string
}

export interface CollectionDetailsResponse {
  details: CollectionDetails
  profiles: Profile[]
}

export interface Media {
  uri: string
  hash: string
  height: number | null
  width: number | null
  mimeType: string
  muxPlaybackId?: string
}

export type CollectionSettings = {
  mintButton?: MintButtonSettings
  theme: ProjectTheme
  media: Media[]
  banner?: Media
  description?: string
  hideRecentActivity?: boolean
  hideStats?: boolean
}

export type ContractAllowlistState =
  | 'addressOnKnownAllowlist'
  | 'alreadyMinted'
  | 'knownAllowlist'
  | 'unknownAllowlist'
  | 'none'

export interface CollectionMinter {
  minter: ProfileId
  count: `${bigint}`
}

export type PointsDetail = {
  safeMintFee: string | undefined
}

export interface ContractTransactionsResponse {
  transactions: ContractTransaction[]
  allowlist?: ContractAllowlistState
}

export interface ContractTransaction {
  /** the contract to interact with */
  to: AddressHash
  latestBlockTimestamp: string // ISO8601 date
  callData: string
  ethValue: string
  nftCount: `${bigint}`
  /** if 1155, the token this mint cooresponds to **/
  tokenId?: string
  minterCount: number
  isAllowlist: boolean
  isValid: boolean
  failReason?: string
  points?: PointsDetail
  gasLimit?: string
}

export interface SampleNFTsResponse {
  recent: NFTSample[]
  first: NFTSample | null
  profiles: Profile[]
}

export interface NFTSample {
  blockNumber: `${bigint}`
  logIndex: number
  tokenID: string
  contractAddress: Contract
  title: string
  minter: ProfileId
  ethPrice: string
  mintTime: string // ISO8601 date
}

export interface MyMintsNFTSample extends NFTSample {
  contractName: string
}

export enum NameKind {
  Address = 'address',
  ENS = 'ens',
  OpenSea = 'opensea',
  PartyBid = 'partybid',
}

export interface AddressObject {
  value: AddressHash
  kind: 'ethereum' | 'unknown'
}

export interface Profile {
  id: ProfileId
  address: AddressObject
  name: string
  nameKind: NameKind
  slug: ProfileSlug
  isFollowing?: boolean
}

// When adding an id, need to also update
// api/pkg/accounts/user.go
export type Checkpoint =
  | 'email-toast'
  | 'customize-cta-banner'
  | 'migrate-ctx-follows'
  | 'viewed-wrapped'

export interface Account {
  id: AccountId
  address: AddressHash
  profile: Profile
  email?: string
  emailVerified: boolean
  settings: Partial<Record<SettingsKey, boolean>>
  checkpoints?: Partial<Record<Checkpoint, string>>
}

export type SettingsKey = 'emailAllowlist'

export type MintTimesResponse = [string, `${bigint}`][]

export interface RequestStatus {
  success: boolean
  message?: string
  error?: boolean
}

export interface AuthResponse {
  success: boolean
  error?: boolean
  message?: string
}

export type Flags = {
  [key: string]: boolean
}

export interface PaginatedResponse {
  cursor: string | null
}

export interface CollectionSocialProofResponse extends PaginatedResponse {
  count: number
  items: Array<{
    count: `${bigint}`
    profile: Profile
  }>
}

export interface ProjectResult {
  address: Contract
  name: string
  minterCount?: string
}

export interface ProfileResult {
  profile: Profile
  mintStats: MintStats
}

export interface SearchQueryResponse {
  projects: ProjectResult[] | null
  profiles: ProfileResult[] | null
}

export interface SearchSuggestion {
  minters: number
  name: string
  address: Contract
}

export interface SearchSuggestionsResponse {
  suggestions: SearchSuggestion[]
}

export enum ReportOption {
  ContractNotTrustworthy = 'ContractNotTrustworthy',
  IntellectualPropertyTheft = 'IntellectualPropertyTheft',
  IllegalOrHarmfulContent = 'IllegalOrHarmfulContent',
  QuestionableTeam = 'QuestionableTeam',
}

export type CollectionFlag =
  | 'removedFromOpensea'
  | 'userReported'
  | 'notEtherscanVerified'

export interface PendingTransactionsResponse {
  total: number
  wallets: number
  lastHour?: string
}

export interface PendingEmailStateResponse {
  sentAt: string | null
}

export interface MintStats {
  totalMints: `${bigint}`
  totalProjects: number
}

export interface MyMintsResponse extends PaginatedResponse {
  items: MyMintsNFTSample[]
  stats: MintStats
}

export type SubmitTxInfo = {
  contract: Contract
  callData: string
  ethValue: string
  from: string
  merkleRoot?: string // omit empty
}

export type SubmitTxSuggestionRequestData = {
  txInfo: SubmitTxInfo
  signature: string
  signer: string
}

export type MintButtonSettings = {
  backgroundColor?: HexColor
  textColor?: HexColor
}

export type UpdateProjectSettingsOptions = {
  contract: Contract
  theme?: ProjectTheme
  mediaHashes?: string[]
  bannerHash?: string
  clearBanner?: boolean
  description?: string
  hideRecentActivity?: boolean
  hideStats?: boolean
  clearMintButton?: boolean
  mintButton?: MintButtonSettings
}

export type MintStatus = 'notAvailable' | 'noMints' | 'mintingNow' | 'mintedOut'

export type FeaturedProject = {
  collection: Collection
  sampleNFTs: NFTSample[]
  transactions: ContractTransaction[]
  profiles: Profile[]
}

export type FeaturedProjectResponse = {
  featuredProjects: FeaturedProject[] | null
}

export type ProfileResponse = {
  profile: Profile
  deployedContractCount: number
  followingCount: number
  followerCount: number
}

export type ProfileProjectsResponse = {
  collections: Collection[]
  cursor: string | null
  stats: {
    totalProjects: number
    totalTokens: string
  }
}

export type PaginatedProfileResponse = {
  items: Profile[]
  cursor: string | null
}

export type OfferSortType =
  | 'topBidValue'
  | 'floorDifferencePercentage'
  | 'dateCreated'

export type Offer = {
  id: string
  collectionAddress: Contract
  makerId: ProfileId
  source: {
    name: string
    url: string
  }
  token: {
    tokenId: string
    name: string
    reservoirCollectionId: string
  }
  price: {
    tokenSymbol: string
    decimals: number
    amount: string
  }
  floorDifferencePercentage: number
}

export type OffersResponse = {
  offers: Offer[]
  profiles: Profile[]
  cursor: string | null
  totalOffers: number
}

export type TransactionData = {
  transactionHash: string
  contractAddress: AddressHash
  tokenId: string
  amount: string
  time: string // time
}

export type FunContractCreatedResponse =
  | {
      status: 'found'
      contract: Contract
    }
  | {
      status: 'unknown'
    }

export type MediaUploadResponse = {
  hash: string
}

export type ActivityCollectionEntry = {
  projectName: string
  contract: Contract
  totalMints: `${bigint}`
  proceeds: `${bigint}`
  referralCount: number
  isAuthorized: boolean
}

export type ActivityCollectionsResponse = Array<ActivityCollectionEntry>

export type FunFinaleMintResponse = {
  address: AddressHash
  mintData: {
    color: number
    endurance: number
    level: number
    passMintedTime: number // unix timestamp
    points: number
    streak: number
  }
  signature: Hex
  totalMinted: number
}

export type ContractMintedResponse = {
  count: `${bigint}`
}
