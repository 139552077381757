import React from 'react'
import type { Toast } from 'react-hot-toast'
import EmailSignup from 'components/EmailSignup'
import CustomToast from './CustomToast'

type Props = {
  toast: Toast
  onDismiss?: () => void
}

function EmailSignupToast({ toast, onDismiss }: Props) {
  return (
    <CustomToast
      toast={toast}
      onDismiss={onDismiss}
      fixedWidth
      showCloseButton
      className="font-normal"
    >
      <div className="flex flex-col gap-y-8 pt-2 w-full">
        <EmailSignup textInputVariant="raised" btnVariant="raised" />
      </div>
    </CustomToast>
  )
}

export default React.memo(EmailSignupToast)
