import { SVGAttributes } from 'react'

export default function PlusSquare(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4.286 3.771c0-.426.345-.771.771-.771h11.314c.427 0 .772.345.772.771v.515H4.286V3.77zM4.286 17.143H3.77A.771.771 0 013 16.37V5.057c0-.426.345-.771.771-.771h.515v12.857z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.143 17.143H4.286v.643c0 .355.288.643.643.643.355 0 .642.287.642.642 0 .355.288.643.643.643h.643v.515c0 .426.346.771.772.771h11.314a.771.771 0 00.771-.771v-.515h.515a.771.771 0 00.771-.771V7.629a.771.771 0 00-.771-.772h-.515v-.643a.643.643 0 00-.643-.643.643.643 0 01-.642-.642.643.643 0 00-.643-.643h-.643v12.857zm0 0v.514a.771.771 0 01-.772.772H7.63a.771.771 0 00-.772.771v.514h12.857V6.857H19.2a.771.771 0 00-.771.772v8.742a.771.771 0 01-.772.772h-.514z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="M9.3 11.357c.426 0 .771.346.771.772v1.8a.643.643 0 001.286 0v-1.8c0-.426.346-.772.772-.772h1.8a.643.643 0 000-1.286h-1.8a.771.771 0 01-.772-.771V7.5a.643.643 0 10-1.286 0v1.8a.771.771 0 01-.771.771H7.5a.643.643 0 100 1.286h1.8z"
      ></path>
    </svg>
  )
}
