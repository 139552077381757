import { useContextSelector } from '@fluentui/react-context-selector'
import { Context } from './Provider'

export function useFeatureFlagEnabled(key: string): boolean {
  return useContextSelector(Context, (state) => state[key] === true)
}

export function useFeatureFlagsEnabled(keys: string[]): boolean[] {
  return useContextSelector(Context, (state) =>
    keys.map((key) => state[key] === true),
  )
}
