import pRetry from 'p-retry'

// this should be wrapped around any dynamic imports that we've seen result
// in a ChunkLoadError (most likely any non-component dynamic import)
//
// unfortunately, there are no out of the box solutions for this
// and we are highly restricted by next.js and webpacks reliance on the key
// to a dynamic import being a static string and not passed in dynamically
export default async function importRetry<T>(importCallback: () => Promise<T>) {
  return await pRetry(async () => await importCallback())
}
