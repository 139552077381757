import { SVGAttributes } from 'react'

export default function Check(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20.333 5a.556.556 0 00-.555.556.556.556 0 01-.556.555.556.556 0 00-.555.556.556.556 0 01-.556.555.556.556 0 00-.555.556.556.556 0 01-.556.555.556.556 0 00-.556.556.556.556 0 01-.555.555.556.556 0 00-.556.556.556.556 0 01-.555.556.556.556 0 00-.556.555.555.555 0 01-.555.556.556.556 0 00-.556.555.555.555 0 01-.555.556.556.556 0 00-.556.555.556.556 0 01-.556.556.556.556 0 00-.555.556.556.556 0 01-.556.555.556.556 0 00-.555.556.556.556 0 01-.556.555h-1.11a.556.556 0 01-.556-.555A.556.556 0 007 15a.556.556 0 01-.556-.556.556.556 0 00-.555-.555.556.556 0 01-.556-.556.556.556 0 00-.555-.555.556.556 0 01-.556-.556.556.556 0 00-.555-.555h-1a.667.667 0 00-.667.666v1c0 .307.249.556.556.556.306 0 .555.249.555.556 0 .306.249.555.556.555.306 0 .555.249.555.556 0 .307.249.555.556.555.307 0 .555.249.555.556 0 .307.25.555.556.555.307 0 .555.249.555.556 0 .307.25.555.556.555.307 0 .556.25.556.556 0 .307.248.556.555.556h1.111a.556.556 0 00.556-.556c0-.307.248-.556.555-.556a.556.556 0 00.556-.555c0-.307.249-.556.555-.556a.556.556 0 00.556-.555c0-.307.249-.556.556-.556a.556.556 0 00.555-.555c0-.307.249-.556.556-.556a.556.556 0 00.555-.556c0-.306.249-.555.556-.555a.555.555 0 00.555-.556c0-.306.25-.555.556-.555a.555.555 0 00.555-.556c0-.307.25-.555.556-.555a.556.556 0 00.556-.556c0-.307.248-.555.555-.555a.556.556 0 00.556-.556c0-.307.248-.556.555-.556a.556.556 0 00.556-.555c0-.307.248-.556.555-.556a.556.556 0 00.556-.555c0-.307.249-.556.555-.556A.556.556 0 0022 6.667v-1A.667.667 0 0021.333 5h-1z"
      ></path>
    </svg>
  )
}
