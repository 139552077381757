import { memo, useCallback } from 'react'
import { useBreakpoints } from 'react-match-breakpoints'
import Link from 'next/link'
import CollectionAvatar from 'components/CollectionAvatar'
import { ProjectResult } from 'utils/api'
import { getContractPath } from 'utils/chains'
import classNames from 'utils/classnames'
import { s } from 'utils/helpers'

type ProjectResultProps = ProjectResult & {
  index: number
  isFocused?: boolean
  onMouseOver: (index: number) => void
  onClose: () => void
}

const ProjectResultRow = ({
  name,
  address: contract,
  minterCount,
  isFocused,
  index,
  onClose,
  onMouseOver,
}: ProjectResultProps) => {
  const bp = useBreakpoints()

  const onHandleMouseOver = useCallback(
    () => onMouseOver(index),
    [onMouseOver, index],
  )

  return (
    <Link href={getContractPath(contract)} onClick={onClose}>
      <div
        className={classNames(
          'flex flex-row items-center gap-4 py-4',
          isFocused === true && 'font-bold',
        )}
        onMouseOver={onHandleMouseOver}
      >
        <div>
          <CollectionAvatar
            contract={contract}
            imageSize={32}
            renderSize={32}
          />
        </div>
        <div className="flex flex-col md:items-center md:flex-row">
          <span>
            <span className="font-bold">{name}</span>
            {typeof minterCount !== 'undefined' ? (
              <>
                {bp.md ? ' • ' : ' '}
                {minterCount} Minter{s(Number(minterCount))}
              </>
            ) : null}
          </span>
        </div>
      </div>
    </Link>
  )
}

export default memo(ProjectResultRow)
