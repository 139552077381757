import { captureException } from '@sentry/nextjs'
import { GetServerSidePropsContext } from 'next'
import { mutate } from 'swr'
import { mutateRead } from 'utils/swr'
import { SignedMessage } from 'utils/web3/types'
import { client } from '../client'
import { Account, AuthResponse, RequestStatus, SettingsKey } from '../types'

export const loginOrRegister = async (
  message: SignedMessage,
): Promise<RequestStatus> => {
  const res = await client('POST', 'mintfun/auth', message)

  const serverResp: AuthResponse = await res.json()
  if (serverResp.success) {
    // force the account to be refetched and wait on a successful login
    await mutateRead('mintfun/account', { revalidate: true })
  }
  return { success: serverResp.success, message: serverResp.message }
}

export const logout = async (): Promise<RequestStatus> => {
  const res = await client('DELETE', 'mintfun/auth/session')
  const serverResp: AuthResponse = await res.json()
  if (serverResp.success) {
    // remove account from the cache
    mutate<Account>(
      'mintfun/account',
      () => {
        return undefined
      },
      true,
    )
  }
  return { success: serverResp.success }
}

export const getIsLoggedIn = async (
  ctx?: GetServerSidePropsContext,
): Promise<boolean> => {
  const res = await client('HEAD', 'mintfun/account', undefined, ctx)

  // these are the only two possible non-successful responses we regularly expect
  if (!res.ok && ![400, 401].includes(res.status)) {
    const error = new Error(`Unexpected response status: ${res.status}`)

    // sentry context enhancement
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    error.response = res

    console.warn(error)
    captureException(error, { level: 'warning' })
  }

  return res.ok
}

export const verifyEmail = async (token: string): Promise<boolean> => {
  const res = await client('POST', 'mintfun/auth/verify-email', { token })
  const serverResp: { success: boolean } = await res.json()
  return serverResp.success
}

export const sendEmail = async (email: string): Promise<RequestStatus> => {
  const res = await client('POST', 'mintfun/auth/send-email-verification', {
    email: email.trim(),
  })
  const serverResp: RequestStatus = await res.json()
  if (serverResp.success) {
    // force the account to be refetched and wait on a successful login
    await mutateRead('mintfun/account', { revalidate: true })
    await mutateRead('mintfun/auth/email-state', { revalidate: true })
  }
  return serverResp
}

export const updateUserSettings = async (
  settings: Partial<Record<SettingsKey, boolean>>,
): Promise<boolean> => {
  const res = await client('PUT', 'mintfun/account/settings', settings)
  const serverResp: { success: boolean } = await res.json()
  if (serverResp.success) {
    // wait for account to propagate
    await new Promise((resolve) => setTimeout(resolve, 300))
    await mutateRead('mintfun/account', { revalidate: true })
  }
  return serverResp.success
}
