import { useMemo } from 'react'
import qs from 'qs'
import useSWR, { BareFetcher, KeyedMutator, useSWRConfig } from 'swr'
import { PublicConfiguration } from 'swr/_internal'
import { useAccount } from 'utils/useAccount'
import { useSettingsOverride } from 'utils/useLayout'
import { isContract } from 'utils/web3/helpers'
import {
  collectionDetailsEndpoint,
  contractAuthorizedEndpoint,
  contractEndpoint,
  profileEndpoint,
  profileOffersCountEndpoint,
} from './endpoints'
import { fetcher, slugsAreEqual } from './helpers'
import {
  ChainId,
  CollectionDetailsResponse,
  CollectionResponse,
  CollectionSettings,
  Contract,
  ProfileResponse,
  ProfileSlug,
  SubmitTxSuggestionRequestData,
} from './types'

export * from './helpers'
export * from './client'
export * from './types'

type UseApiResponseOptions<T> = {
  revalidateOnFocus?: boolean
  revalidateOnMount?: boolean
  revalidateOnReconnect?: boolean
  revalidateIfStale?: boolean
  keepPreviousData?: boolean
  skipFetching?: boolean
  refreshInterval?: number | ((latestData: T | undefined) => number)
  onSuccess?: (
    data: T,
    key: string,
    config: Readonly<PublicConfiguration<T, Error, BareFetcher<T>>>,
  ) => void
}

export type UseApiResponseData<T> = {
  data: T | undefined
  isLoading: boolean
  isError: boolean
  mutate: KeyedMutator<T>
}

export function useAPIResponse<T>(
  route: string,
  initialData?: T,
  options?: UseApiResponseOptions<T>,
): UseApiResponseData<T> {
  const { data, error, mutate } = useSWR<T, Error & { status?: number }>(
    options?.skipFetching ?? false ? null : route,
    fetcher,
    {
      fallbackData: initialData,
      ...options,
    },
  )

  return {
    data: error !== undefined ? undefined : data,
    isLoading: error === undefined && data === undefined,
    isError: Boolean(error),
    mutate,
  }
}

export function useAPIResponseForAddress<T>(
  route: string,
  initialData?: T,
  options?: UseApiResponseOptions<T>,
): UseApiResponseData<T> {
  const { address } = useAccount()

  const skipAddressFetching =
    options?.skipFetching !== undefined
      ? options.skipFetching
      : address === undefined

  const addressUrl = useMemo(() => {
    // add `address=${address}` to the query string
    const [base, query] = route.split('?')
    const parsed = qs.parse(query)
    const newQuery = {
      ...parsed,
      address,
    }
    return `${base}${qs.stringify(newQuery, { addQueryPrefix: true })}`
  }, [address, route])

  const addressData = useAPIResponse<T>(addressUrl, initialData, {
    skipFetching: skipAddressFetching,
    ...options,
  })

  // if we're skipping address fetching, fallback to the user provided option
  // otherwise skip only if address specifid data is valid
  const skipPublicFetching = skipAddressFetching
    ? undefined
    : options?.skipFetching === true
    ? true
    : addressData.data !== undefined

  const publicData = useAPIResponse<T>(route, initialData, {
    skipFetching: skipPublicFetching,
    ...options,
  })

  if (addressData.data !== undefined || addressData.isError) {
    return addressData
  }

  return publicData
}

export const useCollectionResponse = (
  contract: Contract | undefined,
  initialData?: CollectionResponse,
  options?: UseApiResponseOptions<CollectionResponse>,
): CollectionResponse | undefined =>
  useAPIResponse<CollectionResponse>(
    contract !== undefined ? contractEndpoint({ contract }) : '',
    initialData,
    contract !== undefined ? options : { skipFetching: true },
  ).data

export const useCollectionDetails = (
  contract: Contract,
  initialData?: CollectionDetailsResponse,
  options?: UseApiResponseOptions<CollectionDetailsResponse>,
) =>
  useAPIResponse<CollectionDetailsResponse>(
    collectionDetailsEndpoint({ contract }),
    initialData,
    options,
  )

export const useCollectionSettings = (
  contract: Contract,
  initialData?: CollectionResponse,
): CollectionSettings | undefined => {
  const colResponse = useCollectionResponse(contract, initialData)
  const settingsOverride = useSettingsOverride()
  return settingsOverride ?? colResponse?.collection.settings
}

export const submitTxSuggestion = async (
  data: SubmitTxSuggestionRequestData,
) => {
  const response = await fetch('/api/mintfun/tx-suggestion', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    throw new Error('Failed to submit tx suggestion')
  }
}

export const useIsAuthorizedForContract = (contract: Contract): boolean => {
  const { address } = useAccount()
  const apiRoute = useMemo(() => {
    if (address === undefined || !isContract(contract)) return ''
    return contractAuthorizedEndpoint({
      contract,
      address,
    })
  }, [address, contract])

  const validRequestData = apiRoute !== ''
  const { data } = useAPIResponse<{ authorized: boolean }>(
    apiRoute,
    undefined,
    {
      skipFetching: !validRequestData,
    },
  )

  return validRequestData ? data?.authorized ?? false : false
}

export const useProfile = (
  slug: ProfileSlug,
  initialData?: ProfileResponse,
  options?: UseApiResponseOptions<ProfileResponse>,
) => {
  const { mutate } = useSWRConfig()

  const data = useAPIResponse<ProfileResponse>(
    profileEndpoint({ slug }),
    initialData,
    {
      ...options,
      onSuccess: (data, key, config) => {
        options?.onSuccess?.(data, key, config)

        if (slugsAreEqual(slug, data.profile.slug)) {
          return
        }

        mutate(profileEndpoint({ slug: data.profile.slug }), data, false)
      },
    },
  )

  return data
}

export const useOffersForProfileSlug = (
  slug: ProfileSlug | undefined,
  chain: ChainId,
) => {
  const { data } = useAPIResponse<{ totalOffers: number }>(
    profileOffersCountEndpoint({ slug: slug ?? '', chainId: chain }),
    undefined,
    {
      skipFetching: slug === undefined,
    },
  )

  return data?.totalOffers ?? 0
}
