import * as allChains from 'viem/chains'
import { configureChains } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from 'wagmi/providers/public'
import { defaultChain, isSupportedChainId } from 'utils/chains'

export const configureChainsFromEnvironment = () => {
  const mainChain = Object.values(allChains).find(
    (chain) => chain.id === defaultChain.id,
  )
  const otherChains = Object.values(allChains).filter(
    (chain) => chain.id !== defaultChain.id && isSupportedChainId(chain.id),
  )
  const preconfiguredChains = []
  if (mainChain !== undefined) preconfiguredChains.push(mainChain)
  preconfiguredChains.push(...otherChains)

  const { chains } = configureChains(preconfiguredChains, [
    jsonRpcProvider({
      rpc: (chain) =>
        chain.id === 1
          ? { http: 'https://twilight-magical-pine.secure.quiknode.pro' }
          : chain.id === 8453
          ? { http: 'https://stylish-delicate-sunset.secure.quiknode.pro' }
          : chain.id === 10
          ? { http: 'https://flashy-blissful-snowflake.secure.quiknode.pro' }
          : null,
    }),
    publicProvider(), // required for Zora because they don't support Alchemy
  ])

  return { chains }
}
