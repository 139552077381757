import classnames from 'utils/classnames'

type Props = {
  label?: string
  className?: string
}

export default function Badge({ label, className }: Props) {
  return (
    <div
      className={classnames(
        'flex items-center justify-center font-bold text-white bg-badge rounded-full',
        'text-xs min-w-6 h-6',
        className,
      )}
    >
      {label !== undefined && <span>{label}</span>}
    </div>
  )
}
