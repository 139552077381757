import { useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  createContext,
  useContextSelector,
} from '@fluentui/react-context-selector'
import { useBoolean } from 'hooks/useBoolean'
import SearchModal from 'components/Search/SearchModal'
import useSearchSuggestions from 'components/Search/hooks'

type ContextValue = {
  openModal: () => void
}

const SearchModalContext = createContext<ContextValue>(
  undefined as unknown as ContextValue,
)

export const useSearchModal = () =>
  useContextSelector(SearchModalContext, ({ openModal }) => ({
    openModal,
  }))

type Props = {
  children: React.ReactNode
}

export default function SearchModalProvider({ children }: Props) {
  const suggestions = useSearchSuggestions()
  const {
    state: isOpen,
    setTrue: openModal,
    setFalse: closeModal,
  } = useBoolean(false)

  const handleOpenModal = useCallback(
    (event) => {
      event.preventDefault()
      openModal()
    },
    [openModal],
  )

  useHotkeys('esc', closeModal)
  useHotkeys('mod+k', handleOpenModal)

  return (
    <SearchModalContext.Provider value={{ openModal }}>
      {children}

      {isOpen ? (
        <SearchModal suggestions={suggestions} onClose={closeModal} />
      ) : null}
    </SearchModalContext.Provider>
  )
}
