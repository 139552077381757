import { getDefaultConfig } from 'connectkit'
import { createConfig } from 'wagmi'
import { walletConnectProjectId } from 'utils/constants'
import { configureChainsFromEnvironment } from '../helpers'

const { chains } = configureChainsFromEnvironment()

export const client = createConfig(
  getDefaultConfig({
    autoConnect: true,
    appName: 'mint.fun',
    appIcon: 'https://mint.fun/icon-wallet.png',
    walletConnectProjectId,
    chains,
  }),
)
