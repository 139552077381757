import { useCallback, useMemo } from 'react'
import { AddressHash, Contract } from 'utils/api'
import { getContractPath } from 'utils/chains'
import { farcasterHandle, xHandle } from 'utils/constants'
import { getComposeUrl } from 'utils/warpcast'
import { getXPostUrl } from 'utils/x'

export const useTokenIdString = (tokenIds: string[]) =>
  useMemo(() => {
    if (tokenIds.length === 0) {
      return ''
    }

    const tokenNames = tokenIds.map((tokenId) => `#${tokenId}`)
    if (tokenNames.length === 1) {
      return tokenNames[0]
    }

    if (tokenNames.length < 4) {
      const excludingLastElement = tokenNames.slice(0, -1).join(', ')
      return `${excludingLastElement} and ${tokenNames[tokenNames.length - 1]}`
    }

    // add "and x more"
    const firstTwo = tokenNames.slice(0, 2).join(', ')
    return `${firstTwo} and ${tokenNames.length - 2} more`
  }, [tokenIds])

export const useHandleXMintClick = (
  collectionContract: Contract,
  collectionName?: string,
  minterAddress?: AddressHash,
) =>
  useCallback(() => {
    let body = `I just minted on @${xHandle}`
    if (collectionName !== undefined && collectionName !== '') {
      body = `I just minted ${collectionName} on @${xHandle}`
    }

    const url = `https://mint.fun${getContractPath(collectionContract)}${
      minterAddress !== undefined ? `?ref=${minterAddress}` : ''
    }`
    const xIntentUrl = getXPostUrl(body, url, xHandle)

    window.open(xIntentUrl, '_blank')
  }, [collectionContract, collectionName, minterAddress])

export const useHandleFarcasterMintClick = (
  collectionContract: Contract,
  collectionName?: string,
  minterAddress?: AddressHash,
) =>
  useCallback(() => {
    let body = `I just minted on @${farcasterHandle}`
    if (collectionName !== undefined && collectionName !== '') {
      body = `I just minted ${collectionName} on @${farcasterHandle}`
    }

    const url = `https://mint.fun${getContractPath(collectionContract)}${
      minterAddress !== undefined ? `?ref=${minterAddress}` : ''
    }`
    const farcastIntentUrl = getComposeUrl(body, url)

    window.open(farcastIntentUrl, '_blank')
  }, [collectionContract, collectionName, minterAddress])
