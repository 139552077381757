import { useMemo } from 'react'
import useSWR from 'swr'
import { mainnet } from 'viem/chains'
import { useNetwork } from 'wagmi'
import {
  Account,
  AddressHash,
  ProfileSlug,
  accountEndpoint,
  fetcher,
  slugsAreEqual,
  useProfile,
} from 'utils/api'
import useWalletAccount from './useWalletAccount'
import { displayNamify } from './web3/helpers'

type WalletState =
  | {
      isLoadingWallet: true
      isWalletConnected: false
      address: undefined
      chainId: undefined
      connectorName: undefined
    }
  | {
      isLoadingWallet: false
      isWalletConnected: true
      address: AddressHash
      chainId: number
      connectorName: string | undefined
    }
  | {
      isLoadingWallet: false
      isWalletConnected: false
      address: undefined
      chainId: undefined
      connectorName: undefined
    }

type AccountState =
  | {
      account: Account
      isLoggedIn: true
    }
  | {
      account: undefined
      isLoggedIn: false
    }

type ActorState = {
  displayName: string | undefined
  displaySlug: ProfileSlug | undefined
}

export type AccountHookResponse = WalletState & AccountState & ActorState

export function useAccount(): AccountHookResponse {
  const {
    address,
    connectorName,
    isLoading: isLoadingWallet,
    isConnected: isWalletConnected,
  } = useWalletAccount()

  const { data, error } = useSWR<Account, Error & { status?: number }>(
    address === undefined ? null : accountEndpoint,
    fetcher,
    {
      onErrorRetry: (error) => {
        if (error.status === 404 || error.status === 400) {
          return
        }
      },
    },
  )

  const { chain: activeChain } = useNetwork()

  // default to mainnet if we don't have it
  const chainId = activeChain?.id ?? mainnet.id

  const account = error !== undefined ? undefined : data
  const isLoggedIn = account !== undefined

  const { data: profileResponse } = useProfile(
    address ?? ('' as ProfileSlug),
    undefined,
    {
      skipFetching: address === undefined,
    },
  )

  // prefer connected wallet, then account, then nothing
  // this order is chosen because most of the time you're minting on the website,
  // so you want to see your connected wallet
  const actorState = useMemo<ActorState>(() => {
    if (isWalletConnected) {
      if (
        profileResponse !== undefined &&
        slugsAreEqual(address, profileResponse.profile.address.value)
      ) {
        return {
          displayName: profileResponse.profile.name,
          displaySlug: profileResponse.profile.slug,
        }
      }

      return {
        displayName: displayNamify(address),
        displaySlug: address,
      }
    }

    if (isLoggedIn) {
      return {
        displayName: account.profile.name,
        displaySlug: account.profile.slug,
      }
    }

    return {
      displayName: undefined,
      displaySlug: undefined,
    }
  }, [
    account?.profile.name,
    account?.profile.slug,
    address,
    isLoggedIn,
    isWalletConnected,
    profileResponse,
  ])

  const accountState = useMemo<AccountState>(
    () =>
      isLoggedIn
        ? {
            account,
            isLoggedIn,
          }
        : {
            account,
            isLoggedIn,
          },
    [account, isLoggedIn],
  )

  const walletState = useMemo<WalletState>(() => {
    if (isLoadingWallet) {
      return {
        isLoadingWallet: true,
        isWalletConnected: false,
      }
    }

    return isWalletConnected
      ? {
          isLoadingWallet: false,
          isWalletConnected: true,
          address: address as AddressHash,
          chainId,
          connectorName,
        }
      : {
          isLoadingWallet: false,
          isWalletConnected: false,
          address: undefined,
          chainId: undefined,
          connectorName: undefined,
        }
  }, [address, chainId, connectorName, isLoadingWallet, isWalletConnected])

  const response = useMemo<AccountHookResponse>(
    () => ({
      ...walletState,
      ...accountState,
      ...actorState,
    }),
    [accountState, actorState, walletState],
  )

  return response
}
