import React, { useMemo } from 'react'
import { ConnectKitProvider } from 'connectkit'
import { useTheme } from 'next-themes'
import { logout } from 'utils/api/auth/utils'
import { useHasMounted } from 'utils/react-helpers'
import CustomAvatar from './CustomAvatar'
import { ConnectKitTheme } from './types'

const customTheme: ConnectKitTheme = {
  '--ck-font-family': 'DataFun, monospace',
  '--ck-border-radius': '0.5rem',
  '--ck-qr-border-radius': '0.375rem',
  '--ck-primary-button-border-radius': '0.375rem',
  '--ck-primary-button-hover-border-radius': '0.375rem',
  '--ck-primary-button-active-border-radius': '0.375rem',
  '--ck-secondary-button-border-radius': '0.375rem',
  '--ck-secondary-button-hover-border-radius': '0.375rem',
  '--ck-tertiary-button-border-radius': '0.375rem',
  '--ck-tertiary-button-hover-border-radius': '0.375rem',
}

function Disclaimer() {
  return (
    <div className="text-xs">
      By connecting you agree to our{' '}
      <a
        href="/tos"
        target="_blank"
        rel="noreferrer noopener"
        className="bold hover:underline"
      >
        terms of service
      </a>{' '}
      and{' '}
      <a
        href="/privacy"
        target="_blank"
        rel="noreferrer noopener"
        className="bold hover:underline"
      >
        privacy policy
      </a>
      .
    </div>
  )
}

type Props = {
  children: React.ReactNode
}

const WalletConnectProvider = ({ children }: Props) => {
  const { theme } = useTheme()
  const hasMounted = useHasMounted()

  const mode = useMemo(() => {
    if (!hasMounted) return 'dark'
    return theme === 'light' ? 'light' : 'dark'
  }, [hasMounted, theme])

  return (
    <ConnectKitProvider
      mode={mode}
      customTheme={customTheme}
      options={{
        customAvatar: CustomAvatar,
        disclaimer: <Disclaimer />,
        enforceSupportedChains: false,
        initialChainId: 0,
      }}
      onDisconnect={logout}
    >
      {children}
    </ConnectKitProvider>
  )
}

export default React.memo(WalletConnectProvider)
