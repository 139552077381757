import { SVGProps } from 'react'

type ResponsiveIconProps = Partial<SVGProps<SVGSVGElement>> & {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  defaultSize: {
    width?: number
    height?: number
  }
  responsiveSize: {
    width?: number
    height?: number
  }
}

const ResponsiveIcon = ({
  icon,
  defaultSize,
  responsiveSize,
  ...rest
}: ResponsiveIconProps) => {
  return (
    <>
      {icon({
        className: 'flex md:hidden',
        width: responsiveSize.width,
        height: responsiveSize.height,
        ...rest,
      })}
      {icon({
        className: 'hidden md:flex',
        width: defaultSize.width,
        height: defaultSize.height,
        ...rest,
      })}
    </>
  )
}

export default ResponsiveIcon
