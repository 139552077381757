import React from 'react'
import NextHead from 'next/head'
import MetaTags from 'components/MetaTags'
import { siteDescription } from 'utils/meta'

function Head() {
  return (
    <>
      <MetaTags
        description={siteDescription}
        imageUrl={'https://mint.fun/default-meta-image.png'}
      />
      <NextHead>
        <link rel="apple-touch-icon" sizes="180x180" href="/icon180.png?v=4" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/icon32.png?v=4"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/icon16.png?v=4"
        />
        <link rel="manifest" href="/site.webmanifest?v=4" />
        <link rel="shortcut icon" href="/favicon.ico?v=4" />
        <meta name="msapplication-TileColor" content="#171717" />
        <meta
          name="theme-color"
          content="#171717"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#171717"
          media="(prefers-color-scheme: dark)"
        />

        {/* disable ios zoom to input */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </NextHead>
    </>
  )
}

export default React.memo(Head)
