import { memo, useCallback, useEffect, useState } from 'react'
import Link from 'next/link'
import { CreateButton } from 'components/CreateButton'
import ResponsiveIcon from 'components/ResponsiveIcon'
import Logo from 'components/icons/Logo'
import { Spinner } from 'components/icons/Spinner'
import classnames from 'utils/classnames'
import { usePrevious } from 'utils/react'
import { useAccount } from 'utils/useAccount'
import { useOpenConnectModal } from 'utils/web3/walletconnect'
import ProfileButton from './ProfileButton'
import WalletButton from './WalletButton'

function SiteNav() {
  const { isWalletConnected, isLoggedIn, isLoadingWallet } = useAccount()
  const [profileOpen, profileOpenSet] = useState(false)
  const [walletOpen, walletOpenSet] = useState(false)
  const [initWalletLoaded, initWalletLoadedSet] = useState(false)
  const { openConnectModal } = useOpenConnectModal()

  const handleWalletClick = useCallback(() => {
    walletOpenSet(true)
  }, [])

  const handleProfileClick = useCallback(() => {
    if (isWalletConnected || isLoggedIn) {
      profileOpenSet(true)
    } else {
      openConnectModal()
    }
  }, [isLoggedIn, isWalletConnected, openConnectModal])

  const prevIsLoadingWallet = usePrevious(isLoadingWallet, isLoadingWallet)

  useEffect(() => {
    if (
      isWalletConnected ||
      (prevIsLoadingWallet === true && !isLoadingWallet)
    ) {
      initWalletLoadedSet(true)
    }
  }, [isLoadingWallet, isWalletConnected, prevIsLoadingWallet])

  return (
    <nav className="flex justify-between items-center my-6 md:my-8 gap-2 min-h-[52px]">
      <Link href="/feed/trending" className="relative md:top-0 top-[-2px]">
        <ResponsiveIcon
          icon={Logo}
          responsiveSize={{ height: 32 }}
          defaultSize={{ height: 32 }}
        />
        <span className="sr-only">mint.fun</span>
      </Link>
      <div
        className={classnames(
          'flex items-center gap-3 md:gap-4',
          'percy-ignore',
        )}
      >
        {initWalletLoaded ? (
          <>
            <CreateButton />
            <div
              className={classnames(
                'flex rounded-md',
                'divide-x-2 divide-neutral-200 dark:divide-neutral-800',
                'bg-[rgba(255, 255, 255, 0.3)] dark:bg-[rgba(25,25,25,0.3)]',
                'border-2 border-neutral-200 dark:border-neutral-800',
              )}
            >
              <WalletButton
                open={walletOpen}
                openSet={walletOpenSet}
                handleButtonClick={handleWalletClick}
              />
              <ProfileButton
                open={profileOpen}
                openSet={profileOpenSet}
                handleButtonClick={handleProfileClick}
              />
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </nav>
  )
}

export default memo(SiteNav)
