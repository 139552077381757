import React from 'react'
import type { Toast } from 'react-hot-toast'
import { BlockExplorerLogo } from 'components/BlockExplorerLogo'
import { ButtonLink } from 'components/Button'
import { ChainId } from 'utils/api'
import { getChainByIdWithDefault } from 'utils/chains'
import CustomToast from './CustomToast'

type Props = {
  chainId: ChainId
  toast: Toast
  transactionHash: string
  text: string
}

function ViewTransactionOnBlockExplorerToast({
  chainId,
  toast,
  transactionHash,
  text,
}: Props) {
  const {
    blockExplorer: { url, name },
  } = getChainByIdWithDefault(chainId)

  return (
    <CustomToast toast={toast} fixedWidth showCloseButton>
      <div className="flex flex-col gap-4">
        <div>{text}</div>
        <div className="flex gap-4">
          <ButtonLink
            variant="raised"
            className="flex-1"
            href={`${url}/tx/${transactionHash}`}
            target="_blank"
            rel="noopener noreferrer"
            label={`View on ${name}`}
            icon={
              <BlockExplorerLogo
                chainId={chainId}
                className="w-[1em] h-[1em]"
              />
            }
          />
        </div>
      </div>
    </CustomToast>
  )
}

export default React.memo(ViewTransactionOnBlockExplorerToast)
