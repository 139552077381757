import { memo } from 'react'
import { Contract } from 'utils/api'
import classNames from 'utils/classnames'
import { lazy } from 'utils/lazy'

const BrowserAvatar = lazy({
  loader: () => import('./BrowserAvatar'),
  Fallback: () => null,
})

export type Props = {
  contract: Contract
  imageSize: number
  renderSize?: number
  className?: string
}

const Avatar = ({ contract, imageSize, renderSize, className }: Props) => {
  return (
    <div
      style={{ width: renderSize, height: renderSize }}
      className={classNames('aspect-square', className)}
    >
      <BrowserAvatar contract={contract} size={imageSize} />
    </div>
  )
}

export default memo(Avatar)
