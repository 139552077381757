import { SVGAttributes } from 'react'

export default function XIcon(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.667 4A.667.667 0 002 4.667V5c0 .368.298.667.667.667H3c.368 0 .667.298.667.666v.334c0 .368.298.666.666.666h1V10c0 .368.299.667.667.667h1v1c0 .368.298.666.667.666h1V14h-1a.667.667 0 00-.667.667v1H6a.667.667 0 00-.667.666v1h-1a.667.667 0 00-.666.667v1h-1a.667.667 0 00-.667.667V20c0 .368.298.667.667.667H3A.667.667 0 003.667 20v-1h1a.667.667 0 00.666-.667v-1h1A.667.667 0 007 16.667v-1h1A.667.667 0 008.667 15v-1h1.666v2.667c0 .368.299.666.667.666h1V20c0 .368.299.667.667.667h8.666A.667.667 0 0022 20v-.333a.667.667 0 00-.667-.667H21a.667.667 0 01-.667-.667v-2a.667.667 0 00-.666-.666h-1v-1A.667.667 0 0018 14h-1v-1a.667.667 0 00-.667-.667h-1V9h1A.667.667 0 0017 8.333v-1h1a.667.667 0 00.667-.666v-1h2.666A.667.667 0 0022 5v-.333A.667.667 0 0021.333 4h-2a.667.667 0 00-.666.667v1h-1a.667.667 0 00-.667.666v1h-1a.667.667 0 00-.667.667v1h-1.666V8A.667.667 0 0013 7.333h-1V4.667A.667.667 0 0011.333 4H2.667zM12 7.333v1c0 .369.299.667.667.667h1v2.667c0 .368.298.666.666.666h1v1c0 .368.299.667.667.667h1v1c0 .368.299.667.667.667h1v2.666A.667.667 0 0118 19h-3.667a.667.667 0 01-.666-.667V18a.667.667 0 00-.667-.667h-1v-2.666a.667.667 0 00-.667-.667h-1v-1a.667.667 0 00-.666-.667h-1v-1A.667.667 0 008 10.667H7V8a.667.667 0 00-.667-.667h-1v-1c0-.368.299-.666.667-.666h3.667c.368 0 .666.298.666.666v.334c0 .368.299.666.667.666h1z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
