import Link from 'next/link'
import classnames from 'utils/classnames'
import PlusSquare from './icons/PlusSquare'

type Props = {
  className?: string
}

export const CreateButton = ({ className }: Props) => {
  return (
    <Link
      href="/create"
      className={classnames(
        'font-bold',
        'flex items-center gap-2 relative select-none py-[9px] px-2',
        'group group-aria aria-disabled:pointer-events-none aria-disabled:opacity-50',
        'aria-busy:pointer-events-none aria-busy:opacity-50',
        className,
      )}
    >
      <PlusSquare />
      <span className="sr-only">Create</span>
    </Link>
  )
}
