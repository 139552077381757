import { ReactNode, useCallback, useState } from 'react'
import {
  createContext,
  useContextSelector,
} from '@fluentui/react-context-selector'
import MintModal, { MintEvent } from 'components/MintModal'

type ContextValue = {
  openModal: (mintEvent: MintEvent) => void
  closeModal: () => void
}

const MintModalContext = createContext<ContextValue>(
  undefined as unknown as ContextValue,
)

export const useMintModal = () =>
  useContextSelector(MintModalContext, ({ openModal, closeModal }) => ({
    openModal,
    closeModal,
  }))

type Props = {
  children: ReactNode
}

export default function MintModalProvider({ children }: Props) {
  const [mintEvent, mintEventSet] = useState<MintEvent | undefined>(undefined)
  const isOpen = mintEvent !== undefined

  const openModal = useCallback(
    (mintEvent: MintEvent) => mintEventSet(mintEvent),
    [],
  )

  const closeModal = useCallback(() => mintEventSet(undefined), [])

  return (
    <MintModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <MintModal open={isOpen} onClose={closeModal} mintEvent={mintEvent} />
    </MintModalContext.Provider>
  )
}
