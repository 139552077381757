import { ReactNode } from 'react'
// eslint-disable-next-line no-restricted-imports
import { useAccount } from 'wagmi'
import { clientRetry, interactionEndpoint } from 'utils/api'

type Props = {
  children: ReactNode
}

export default function AnalyticsProvider({ children }: Props) {
  useAccount({
    onConnect({ address, isReconnected }) {
      if (address === undefined || isReconnected) {
        return
      }

      try {
        clientRetry('POST', interactionEndpoint, {
          type: 'connect',
          address,
        })
      } catch (error) {
        console.error(error)
      }
    },
  })

  return <>{children}</>
}
