import { memo } from 'react'
import WalletIcon from 'components/icons/Wallet'
import { AddressHash } from 'utils/api'
import { useHasSeenOffersBadge } from 'utils/checkpoints'
import classnames from 'utils/classnames'
import AvatarWithDecoration, { MismatchInfo } from './AvatarWithDecoration'

type Props = {
  open: boolean
  handleButtonClick: () => void
  avatarAddress: AddressHash | undefined
  mismatchInfo?: MismatchInfo
  isWalletConnected: boolean
  offerCount: number
}

function ProfileNavButton({
  open,
  handleButtonClick,
  avatarAddress,
  mismatchInfo,
  isWalletConnected,
  offerCount,
}: Props) {
  const [hasSeenOffersBadge] = useHasSeenOffersBadge()

  const badgeCount =
    hasSeenOffersBadge !== true && offerCount > 0 ? offerCount : undefined

  return (
    <button
      onClick={handleButtonClick}
      className={classnames(
        'flex items-center gap-2 py-2 px-3',
        open && 'bg-neutral-200 dark:bg-neutral-850',
      )}
    >
      {avatarAddress !== undefined ? (
        <>
          <AvatarWithDecoration
            size={32}
            slug={avatarAddress}
            key={avatarAddress + 32}
            warningLocation="top-right"
            mismatchInfo={mismatchInfo}
            badgeCount={badgeCount}
            className="hidden md:flex"
            transitionIn
          />
          <AvatarWithDecoration
            size={22}
            slug={avatarAddress}
            key={avatarAddress + 22}
            warningLocation="top-right"
            mismatchInfo={mismatchInfo}
            badgeCount={badgeCount}
            className="flex md:hidden"
            transitionIn
          />
        </>
      ) : (
        <WalletIcon className="flex md:hidden" />
      )}
      {!isWalletConnected && (
        <span className="font-bold text-xs hidden md:block">
          Connect Wallet
        </span>
      )}
    </button>
  )
}

export default memo(ProfileNavButton)
