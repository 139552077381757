import { memo, useCallback } from 'react'
import { lazy } from 'utils/lazy'
import { useAccount } from 'utils/useAccount'
import WalletNavButton from './WalletNavButton'

const WalletDropdown = lazy({
  loader: () => import('./WalletDropdown'),
  Fallback: ({ props: { handleButtonClick, open } }) => (
    <WalletNavButton handleButtonClick={handleButtonClick} open={open} />
  ),
})

type Props = {
  open: boolean
  openSet: (openState: boolean) => void
  handleButtonClick: () => void
}

function WalletButton({ open, openSet, handleButtonClick }: Props) {
  const { isWalletConnected } = useAccount()

  const handleLinkClick = useCallback(() => openSet(false), [openSet])

  if (!isWalletConnected) {
    return null
  }

  return (
    <WalletDropdown
      open={open}
      openSet={openSet}
      handleButtonClick={handleButtonClick}
      handleLinkClick={handleLinkClick}
    />
  )
}

export default memo(WalletButton)
