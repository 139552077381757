import { ReactNode } from 'react'
import { initBreakpoints } from 'react-match-breakpoints'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import PlausibleProvider from 'next-plausible'
import { ThemeProvider } from 'next-themes'
import { SWRConfig } from 'swr'
import { WagmiConfig } from 'wagmi'
import BridgingModal from 'components/BridgingModal'
import AnalyticsProvider from 'providers/AnalyticsProvider'
import AuthProvider from 'providers/Auth'
import LayoutProvider from 'providers/Layout'
import MintModalProvider from 'providers/MintModalProvider'
import ReservoirKitProvider from 'providers/ReservoirKit'
import { FeatureFlagsProvider } from 'utils/feature-flags'
import { WalletConnectProvider, client } from 'utils/web3/walletconnect'
import SearchModalProvider from './Search/SearchModalProvider'

const breakpointsConfig = {
  sm: '(min-width: 640px)',
  md: '(min-width: 800px)',
}

const BreakpointsProvider = initBreakpoints(breakpointsConfig)

const queryClient = new QueryClient()

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <SWRConfig value={{ shouldRetryOnError: false }}>
      <QueryClientProvider client={queryClient}>
        <FeatureFlagsProvider>
          <ThemeProvider
            defaultTheme="dark"
            enableSystem={false}
            attribute="class"
          >
            <WagmiConfig config={client}>
              <WalletConnectProvider>
                <AuthProvider>
                  <BreakpointsProvider>
                    <PlausibleProvider
                      domain="mint.fun"
                      enabled={
                        process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === '1'
                      }
                      trackOutboundLinks
                      manualPageviews
                    >
                      <LayoutProvider>
                        <AnalyticsProvider>
                          <ReservoirKitProvider>
                            <SearchModalProvider>
                              <MintModalProvider>
                                <BridgingModal>{children}</BridgingModal>
                              </MintModalProvider>
                            </SearchModalProvider>
                          </ReservoirKitProvider>
                        </AnalyticsProvider>
                      </LayoutProvider>
                    </PlausibleProvider>
                  </BreakpointsProvider>
                </AuthProvider>
              </WalletConnectProvider>
            </WagmiConfig>
          </ThemeProvider>
        </FeatureFlagsProvider>
      </QueryClientProvider>
    </SWRConfig>
  )
}
