import { memo, useCallback, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useRouter } from 'next/router'
import { SearchQueryResponse, contractEndpoint } from 'utils/api'
import { getContractPath } from 'utils/chains'
import { mutateRead } from 'utils/swr'
import ProfileResultRow from './ProfileResultRow'
import ProjectResultRow from './ProjectResultRow'

type SearchResultsProps = {
  results: SearchQueryResponse
  onClose: () => void
}

const SearchResults = ({ results, onClose }: SearchResultsProps) => {
  const { projects, profiles } = results
  const allResults = [...(projects ?? []), ...(profiles ?? [])]

  const router = useRouter()
  const [focusedResultIndex, focusedResultIndexSet] = useState<
    number | undefined
  >()

  const onArrowPress = useCallback(
    (direction: 'up' | 'down') =>
      focusedResultIndexSet((focusedIndex) => {
        if (
          focusedIndex == undefined ||
          (projects == null && profiles == null)
        ) {
          return 0
        }

        const newIndex =
          direction === 'up' ? focusedIndex - 1 : focusedIndex + 1

        if (newIndex < 0) {
          return allResults.length - 1
        }

        if (newIndex >= allResults.length) {
          return 0
        }

        return newIndex
      }),
    [projects, profiles, allResults.length],
  )

  useEffect(() => {
    focusedResultIndexSet(0)
  }, [results])

  useHotkeys('up', () => onArrowPress('up'), { enableOnFormTags: ['input'] })
  useHotkeys('down', () => onArrowPress('down'), {
    enableOnFormTags: ['input'],
  })
  useHotkeys(
    'enter',
    () => {
      const focusedResult =
        focusedResultIndex !== undefined && allResults?.[focusedResultIndex]

      if (focusedResult === undefined || focusedResult === false) {
        return
      }

      const currIdx = focusedResultIndex ?? 0
      const totalProjects = projects?.length ?? 0
      const focusedProject = projects?.[currIdx]?.address
      if (focusedProject !== undefined) {
        router.push(getContractPath(focusedProject))
      } else if (currIdx >= totalProjects) {
        // use profile route if index is over projects length
        router.push(
          `/profile/${
            profiles?.[currIdx - totalProjects]?.profile.slug.toString() ?? ''
          }`,
        )
      }
      onClose()
    },
    { enableOnFormTags: ['input'] },
    [focusedResultIndex, allResults, projects, profiles],
  )

  // prefetch results as they're selected
  useEffect(() => {
    if (
      focusedResultIndex === undefined ||
      projects === null ||
      focusedResultIndex > projects.length
    ) {
      return
    }

    const focusedResult = projects[focusedResultIndex]
    if (focusedResult === undefined) {
      return
    }
    mutateRead(contractEndpoint({ contract: focusedResult.address }))
  }, [focusedResultIndex, results, projects])

  return (
    <div className="flex flex-col mt-4 mb-6">
      {projects !== null && projects.length > 0 && (
        <h3 className="mt-4 mb-2 font-bold">Projects</h3>
      )}
      {(projects ?? []).map(({ name, address, minterCount }, index) => (
        <div key={String(address)}>
          <ProjectResultRow
            name={name}
            address={address}
            minterCount={minterCount}
            index={index}
            isFocused={index === focusedResultIndex}
            onClose={onClose}
            onMouseOver={focusedResultIndexSet}
          />
        </div>
      ))}
      {profiles !== null && profiles.length > 0 && (
        <h3 className="mt-4 mb-2 font-bold">Profiles</h3>
      )}
      {(profiles ?? []).map(
        ({ profile: { address, name, slug }, mintStats }, index) => (
          <div key={address.value}>
            <ProfileResultRow
              name={name}
              slug={slug}
              mintStats={mintStats}
              index={index + (projects?.length ?? 0)}
              isFocused={focusedResultIndex === index + (projects?.length ?? 0)}
              onClose={onClose}
              onMouseOver={focusedResultIndexSet}
            />
          </div>
        ),
      )}
    </div>
  )
}

export default memo(SearchResults)
