import React from 'react'
import { AppProps as NextAppProps } from 'next/app'
import nProgress from 'nprogress'
import { Overwrite } from 'utility-types'
import DefaultLayout from 'components/Layout'
import Providers from 'components/Providers'
import '../styles/global.css'

nProgress.configure({ showSpinner: false, easing: 'ease-out', speed: 150 })

type CustomAppProps = Record<string, unknown>

type AppProps = Overwrite<
  NextAppProps,
  {
    Component: NextAppProps['Component'] & {
      Layout?: React.ComponentType
    }
  }
> &
  CustomAppProps

function AppCustom({ Component, pageProps }: AppProps) {
  const Layout = Component.Layout ?? DefaultLayout

  return (
    <Providers>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </Providers>
  )
}

export default AppCustom
