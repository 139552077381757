import { SVGProps } from 'react'

export default function Search(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.765 13.765c.325 0 .588.263.588.588 0 .325.263.588.588.588.325 0 .588.263.588.588 0 .325.264.589.589.589h1.176c.325 0 .588.263.588.588 0 .325.264.588.589.588h2.352a.588.588 0 00.589-.588c0-.325.263-.588.588-.588h2.353c.325 0 .588.263.588.588 0 .325.263.588.588.588.325 0 .589.264.589.588 0 .325.263.589.588.589.325 0 .588.263.588.588 0 .325.264.588.588.588.325 0 .589.263.589.588 0 .325.263.588.588.588.325 0 .588.264.588.589 0 .325.263.588.588.588h1.06c.389 0 .705-.316.705-.706v-1.059a.588.588 0 00-.588-.588.588.588 0 01-.588-.588.588.588 0 00-.589-.588.588.588 0 01-.588-.589.588.588 0 00-.588-.588.588.588 0 01-.588-.588.588.588 0 00-.589-.588.588.588 0 01-.588-.589.588.588 0 00-.588-.588.588.588 0 01-.588-.588V12c0-.325.263-.588.588-.588a.588.588 0 00.588-.589V8.471a.588.588 0 00-.588-.589.588.588 0 01-.588-.588V6.118a.588.588 0 00-.589-.589.588.588 0 01-.588-.588.588.588 0 00-.588-.588.588.588 0 01-.588-.588.588.588 0 00-.588-.589H12a.588.588 0 01-.588-.588.588.588 0 00-.589-.588H8.471a.588.588 0 00-.589.588.588.588 0 01-.588.588H6.118a.588.588 0 00-.589.589.588.588 0 01-.588.588.588.588 0 00-.588.588.588.588 0 01-.588.588.588.588 0 00-.589.589v1.176a.588.588 0 01-.588.588.588.588 0 00-.588.589v2.352c0 .325.263.589.588.589.325 0 .588.263.588.588v1.177c0 .324.264.588.589.588zm7.058-9.412c.325 0 .589.263.589.588 0 .325.263.588.588.588h1.059c.39 0 .706.316.706.706v1.06c0 .324.263.587.588.587.325 0 .588.264.588.589v2.352a.588.588 0 01-.588.589.588.588 0 00-.588.588v1.059c0 .39-.316.706-.706.706H12a.588.588 0 00-.588.588.588.588 0 01-.589.588H8.471a.588.588 0 01-.589-.588.588.588 0 00-.588-.588H6.235a.706.706 0 01-.706-.706V12a.588.588 0 00-.588-.588.588.588 0 01-.588-.589V8.471c0-.325.263-.589.588-.589a.588.588 0 00.588-.588V6.235c0-.39.316-.706.706-.706h1.06a.588.588 0 00.587-.588c0-.325.264-.588.589-.588h2.352z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
