import { Modal, ModalContent } from 'components/Modal'
import Hourglass from 'components/icons/Hourglass'
import {
  AddressHash,
  Contract,
  PointsDetail,
  getCollectionAvatarAPIURL,
} from 'utils/api'
import classnames from 'utils/classnames'
import { formatTokenAmount } from 'utils/web3/helpers'

export type MintEvent = {
  minterAddress: AddressHash
  collectionContract: Contract
  collectionName: string
  isFunContract: boolean
  points: PointsDetail | undefined
  ethValue: string
  estGasFee: bigint
  gasIsRefunded: boolean
}

type BodyProps = {
  mintEvent: MintEvent
}

function MintModalBody({ mintEvent }: BodyProps) {
  const { collectionName, collectionContract, points } = mintEvent

  const mintCostFormatted = formatTokenAmount({
    amount: mintEvent.ethValue,
    truncateAmount: 4,
  })

  return (
    <div className="w-full pt-4">
      <div className="flex flex-col w-full border-separate border-b border-neutral-200 dark:border-neutral-800 gap-4 p-6">
        <div className="flex w-full text-xl font-bold normal-case">
          {collectionName}
        </div>
        <div className="flex w-full gap-x-4">
          <div className="w-20 h-20 rounded-sm">
            <img src={getCollectionAvatarAPIURL(collectionContract, 80)} />
          </div>
          <div className="flex-1 flex flex-col gap-y-1">
            <div className="flex justify-between items-baseline">
              <div>Mint Price:</div>
              <div className="text-xl font-bold uppercase">
                {mintCostFormatted}
              </div>
            </div>
            <div className="flex justify-between items-baseline">
              <div>Est. gas fee:</div>
              <div
                className={classnames(
                  'opacity-50 uppercase',
                  mintEvent.gasIsRefunded && 'line-through',
                )}
              >
                ~
                {formatTokenAmount({
                  amount: mintEvent.estGasFee,
                  truncateAmount: 4,
                })}
              </div>
            </div>
            <div className="flex justify-between items-baseline">
              <div>Total:</div>
              <div className="opacity-50 uppercase">
                {mintEvent.gasIsRefunded ? (
                  <>{mintCostFormatted}</>
                ) : (
                  <>
                    ~
                    {formatTokenAmount({
                      amount: mintEvent.estGasFee + BigInt(mintEvent.ethValue),
                      truncateAmount: 4,
                    })}
                  </>
                )}
              </div>
            </div>
            {points?.safeMintFee !== undefined && (
              <div className="flex justify-between">
                <div>Safe mint fee:</div>
                <div className="opacity-50 uppercase">
                  ~
                  {formatTokenAmount({
                    amount: points.safeMintFee,
                    truncateAmount: 4,
                  })}{' '}
                  ETH
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {mintEvent.gasIsRefunded && (
        <div className="flex flex-col w-full border-separate border-b border-neutral-200 dark:border-neutral-800 gap-y-4 p-6">
          <p className="text-green font-bold text-left">
            This mint is free. Gas will be refunded in the same transaction.
          </p>
        </div>
      )}
      <div className="flex gap-x-2 w-full p-6 text-left items-center">
        <div className="w-7 h-7">
          <Hourglass className="w-full -h-auto" />
        </div>
        <span className="text-xs">
          Waiting for confirmation from your wallet...
        </span>
      </div>
    </div>
  )
}

type Props = {
  mintEvent: MintEvent | undefined
  open: boolean
  onClose: () => void
}

export default function MintModal({ mintEvent, open, onClose }: Props) {
  return (
    <Modal open={open} onClose={onClose}>
      {mintEvent !== undefined && (
        <ModalContent
          content={<MintModalBody mintEvent={mintEvent} />}
          DialogContent={({ className, children }) => (
            <div
              className={classnames(
                className, // className first because we want to override styles
                'p-0 pt-0 justify-start min-h-0',
              )}
            >
              {children}
            </div>
          )}
        />
      )}
    </Modal>
  )
}
