import { ReactNode, useRef } from 'react'
import useResizeObserver from 'use-resize-observer'
import AuthModal from 'components/AuthModal'
import { Background } from 'components/Background'
import ExplainerBanner from 'components/ExplainerBanner'
import SiteNav from 'components/SiteNav'
import classNames from 'utils/classnames'
import { ToastContainer } from 'utils/toasts'
import { useSidebarOpen } from 'utils/useLayout'
import Head from './Head'
import { useRouteTracking } from './routeTracking'
import useNProgress from './useNProgress'

export default function Layout({ children }: { children: ReactNode }) {
  useRouteTracking()
  useNProgress()

  const pageRef = useRef<HTMLDivElement>(null)
  const sidebarOpen = useSidebarOpen()
  const { width } = useResizeObserver<HTMLElement>({ ref: pageRef })
  const isScreenXL = width !== undefined && width >= 1280
  const isSidebarOffset = isScreenXL && sidebarOpen

  return (
    <>
      <Head />

      <Background />
      <AuthModal />
      <ToastContainer />

      <div className="flex flex-col" ref={pageRef}>
        <ExplainerBanner />
        <div
          className={classNames(
            'w-full max-w-screen-xl self-center',
            'px-3 md:px-8',
            isSidebarOffset && '-mr-sidebar w-sidebar-offset',
          )}
        >
          <SiteNav />
          <main>{children}</main>
        </div>
      </div>
    </>
  )
}
