import { createContext, useContext } from 'react'
import dynamic, { LoadableOptions } from 'next/dynamic'
import importRetry from './importRetry'

// Since we aren't using Suspense yet, we have to use the React.lazy loader API from
// Next.js dynamic. This means we don't have access to the props of the SSR component.
// To get around this, we create a context to access within the loader component.
// Ref: https://github.com/vercel/next.js/issues/7906#issuecomment-787686440

type LoadingStatus<P> = Parameters<
  NonNullable<LoadableOptions<P>['loading']>
>[0]

type LazyOptions<P> = {
  loader: () => Promise<
    React.ComponentType<P> | { default: React.ComponentType<P> }
  >
  Fallback: ({
    props,
    status,
  }: {
    props: P
    status: LoadingStatus<P>
  }) => JSX.Element | null
}

export function lazy<P extends Record<string, unknown>>({
  loader,
  Fallback,
}: LazyOptions<P>) {
  const LazyContext = createContext<P>({} as P)

  const FallbackWithProps = (status: LoadingStatus<P>) => {
    const props = useContext(LazyContext)
    return <Fallback props={props} status={status} />
  }

  const Component = dynamic(importRetry(loader), {
    ssr: false,
    loading: FallbackWithProps,
  })

  const LazyComponent = (props: P) => {
    return (
      <LazyContext.Provider value={props}>
        <Component {...props} />
      </LazyContext.Provider>
    )
  }

  return LazyComponent
}
