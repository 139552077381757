import { useCallback } from 'react'
import { useModal } from 'connectkit'

export { client } from './client'
export { default as WalletConnectProvider } from './WalletConnectProvider'
export { default as WalletConnectButton } from './WalletConnectButton'

export const useOpenConnectModal = (): {
  openConnectModal: () => void
  open: boolean
} => {
  const { open, setOpen } = useModal()
  const openConnectModal = useCallback(() => setOpen(true), [setOpen])
  return { openConnectModal, open }
}
