import { SVGAttributes } from 'react'

export default function CopyIcon(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.692 4.593l-.363-.366a.765.765 0 00-1.088 0l-3.446 3.475a.78.78 0 000 1.097l.363.366-.453.457a.65.65 0 000 .914.65.65 0 010 .914l-.907.915a.637.637 0 01-.907 0 .637.637 0 00-.907 0l-.453.457-.363-.366a.765.765 0 00-1.088 0L5.634 15.93a.78.78 0 000 1.097l.363.366-.363.366a.78.78 0 000 1.097l1.633 1.646c.3.303.787.303 1.088 0l.362-.366.363.366c.3.303.788.303 1.088 0l3.446-3.475a.78.78 0 000-1.097l-.363-.365.454-.458a.65.65 0 000-.914.65.65 0 010-.914l.906-.915a.638.638 0 01.907 0c.25.253.656.253.907 0l.453-.457.363.366c.3.303.788.303 1.088 0L21.775 8.8a.78.78 0 000-1.097l-.363-.366.363-.366a.78.78 0 000-1.097l-1.633-1.646a.765.765 0 00-1.088 0l-.362.366zm0 0l2.72 2.743-4.534 4.571-.453-.457a.65.65 0 010-.914.65.65 0 000-.914.637.637 0 00-.907 0 .638.638 0 01-.907 0l-.453-.457 4.534-4.572zm-5.44 10.972l-4.535 4.571-2.72-2.743 4.534-4.571.453.457a.65.65 0 010 .914.65.65 0 000 .914c.25.253.657.253.907 0a.637.637 0 01.907 0l.453.458z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="M3.795 9.365c.425 0 .77-.347.77-.776V7.426c0-.357.287-.647.64-.647.355 0 .642.29.642.647v1.163c0 .429.344.776.77.776H7.77c.354 0 .64.29.64.647a.644.644 0 01-.64.646H6.616a.773.773 0 00-.769.776v1.164a.644.644 0 01-.641.646.644.644 0 01-.641-.646v-1.164a.773.773 0 00-.77-.776H2.641A.644.644 0 012 10.012c0-.357.287-.647.641-.647h1.154z"
      ></path>
    </svg>
  )
}
