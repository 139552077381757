import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from '@buzuosheng/use-localstorage'
import { Checkpoint, updateCheckpoints } from 'utils/api'
import { useAccount } from 'utils/useAccount'

export const useHasSeenOffersBadge = () =>
  useLocalStorage<boolean>('has-seen-offers-badge', {
    prefix: 'mint.fun',
    age: '10y',
  })

export const useHasDismissedEmailSignup = () =>
  useLocalStorage<boolean>('allowlist-sigup-dismissed', {
    prefix: 'mint.fun',
    age: '10y',
  })

export function useLocalCheckpoint(
  checkpointId: Checkpoint,
  defaultValue = false,
): [boolean, () => void] {
  const [reachedLocalCheckpoint, reachedLocalCheckpointSet] = useLocalStorage<
    number | undefined
  >(`checkpoint-${checkpointId}`, {
    prefix: 'mint.fun',
    age: '10y',
  })

  const reachedLocalCheckpointOrDefault =
    reachedLocalCheckpoint !== undefined ?? defaultValue

  const handleLocalCheckpointReached = useCallback(() => {
    reachedLocalCheckpointSet(Date.now())
  }, [reachedLocalCheckpointSet])

  return [reachedLocalCheckpointOrDefault, handleLocalCheckpointReached]
}

export function useAccountCheckpoint(
  checkpointId: Checkpoint,
  defaultValue = false,
): [boolean, () => void] {
  const { account } = useAccount()
  const accountReachedCheckpointOrDefault =
    account?.checkpoints?.[checkpointId] !== undefined ?? defaultValue

  const [reachedCheckpoint, reachedCheckpointSet] = useState(
    accountReachedCheckpointOrDefault,
  )

  useEffect(
    () => reachedCheckpointSet(accountReachedCheckpointOrDefault),
    [accountReachedCheckpointOrDefault],
  )

  const handleCheckpointReached = useCallback(() => {
    reachedCheckpointSet(true)
    updateCheckpoints(checkpointId)
  }, [checkpointId])

  return [reachedCheckpoint, handleCheckpointReached]
}

export function useAccountOrLocalCheckpoint(
  checkpointId: Checkpoint,
  defaultValue = false,
): [boolean, () => void] {
  const { isLoggedIn } = useAccount()
  const [reachedCheckpoint, handleCheckpointReached] = useAccountCheckpoint(
    checkpointId,
    defaultValue,
  )

  const [reachedLocalCheckpoint, handleLocalCheckpointReached] =
    useLocalCheckpoint(checkpointId, defaultValue)

  // if user is not authenticated, use local checkpoints
  const userReachedCheckpoint = isLoggedIn
    ? reachedLocalCheckpoint || reachedCheckpoint
    : reachedLocalCheckpoint
  const userHandleCheckpointReached = isLoggedIn
    ? handleCheckpointReached
    : handleLocalCheckpointReached

  return [userReachedCheckpoint, userHandleCheckpointReached]
}
