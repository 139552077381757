import React, { useCallback, useEffect, useState } from 'react'
import type { Toast } from 'react-hot-toast'
import Link from 'next/link'
import { formatEther } from 'viem'
import { Button } from 'components/Button'
import TextInput from 'components/TextInput'
import Check from 'components/icons/Check'
import CopyIcon from 'components/icons/CopyIcon'
import Farcaster from 'components/icons/Social/Farcaster'
import XIcon from 'components/icons/Social/X'
import { AddressHash, Contract } from 'utils/api'
import { referralFee } from 'utils/constants'
import { getReferralLink } from 'utils/web3/helpers'
import CustomToast from './CustomToast'
import {
  useHandleFarcasterMintClick,
  useHandleXMintClick,
  useTokenIdString,
} from './util'

type Props = {
  toast: Toast
  tokenIds: string[]
  collectionContract: Contract
  isFunContract: boolean
  collectionName?: string
  minterAddress?: AddressHash
}

const MintReferralButton = ({ referralUrl }: { referralUrl: string }) => {
  const [justCopied, justCopiedSet] = useState(false)

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(referralUrl)
    justCopiedSet(true)
  }, [referralUrl])

  useEffect(() => {
    if (!justCopied) {
      return
    }
    const timeout = setTimeout(() => {
      justCopiedSet(false)
    }, 500)

    return () => clearTimeout(timeout)
  }, [justCopied])

  return (
    <div className="flex rounded-md border-2 border-neutral-200 dark:border-neutral-750">
      <TextInput
        value={referralUrl}
        onChange={() => {
          /* */
        }}
        className="rounded-none bg-neutral-200 dark:bg-neutral-800 !opacity-100 flex-1 rounded-l-[4px]"
        disabled
      />
      <Button
        label=""
        className="rounded-none bg-neutral-200 dark:bg-neutral-750 hover:rounded-r-[4px]" // align to parent border
        variant="raised"
        icon={
          justCopied ? (
            <Check className="h-5 w-5" />
          ) : (
            <CopyIcon className="h-5 w-5" />
          )
        }
        onClick={handleCopy}
      />
    </div>
  )
}

export function ShareCollectionButtons({
  isFunContract,
  collectionName,
  collectionContract,
  minterAddress,
}: {
  isFunContract: boolean
  collectionName: string
  collectionContract: Contract
  minterAddress?: AddressHash
}) {
  const shareLink = getReferralLink(collectionContract, minterAddress)

  const handleXClick = useHandleXMintClick(
    collectionContract,
    collectionName,
    isFunContract ? minterAddress : undefined,
  )

  const handleFarcastClick = useHandleFarcasterMintClick(
    collectionContract,
    collectionName,
    isFunContract ? minterAddress : undefined,
  )
  return (
    <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
      {isFunContract && <MintReferralButton referralUrl={shareLink} />}
      <Button
        variant="raised"
        className="flex-1 dark:bg-neutral-750"
        onClick={handleXClick}
        label="Share"
        icon={<XIcon className="w-[1em] h-[1em]" />}
      />
      <Button
        variant="raised"
        className="flex-1 dark:bg-neutral-750"
        onClick={handleFarcastClick}
        label="Share"
        icon={<Farcaster className="w-[1em] h-[1em]" />}
      />
    </div>
  )
}

function TransactionToast({
  toast,
  tokenIds,
  collectionContract,
  collectionName,
  isFunContract,
  minterAddress,
}: Props) {
  const tokenIdString = useTokenIdString(tokenIds)

  return (
    <CustomToast toast={toast} fixedWidth showCloseButton>
      <div className="flex flex-col gap-4 w-full">
        <div className="pt-4">
          {`Your mint of `}
          <span className="normal-case">
            {collectionName !== undefined && collectionName !== ''
              ? `${collectionName} `
              : ''}
            {tokenIdString}
          </span>
          {` was successful!`}
        </div>
        {isFunContract && (
          <div className="font-normal text-sm">
            Earn {formatEther(referralFee)}{' '}
            <span className="uppercase">ETH</span> in referral rewards when
            someone mints from your share link.{' '}
            <Link className="underline" href="/faq/creators">
              Learn more.
            </Link>
          </div>
        )}
        <ShareCollectionButtons
          isFunContract={isFunContract}
          collectionContract={collectionContract}
          collectionName={collectionName ?? ''}
          minterAddress={minterAddress}
        />
      </div>
    </CustomToast>
  )
}

export default React.memo(TransactionToast)
