import { SVGProps } from 'react'

export default function Logo(props: Partial<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="2312"
      height="1248"
      viewBox="0 0 2312 1248"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2074.99 964.073L2014.29 1196.81C2007.1 1224.75 1979.55 1247.11 1951.59 1247.11H1537.47C1509.51 1247.11 1492.34 1224.35 1499.13 1196.81L1561.83 964.073C1569.01 936.129 1596.57 913.774 1624.52 913.774H2036.65C2064.61 913.774 2081.78 936.129 2074.99 964.073ZM2309.81 63.4703L2130.5 774.452C2125.31 797.206 2106.14 812.775 2083.38 812.775H1669.25C1653.68 812.775 1638.1 805.59 1629.32 793.613C1620.53 781.637 1612.14 752.096 1653.68 722.156L1844.57 583.233C1864.93 568.463 1854.55 536.127 1829.39 536.127H1580.99C1567.02 536.127 1551.44 528.942 1542.66 516.965C1532.27 504.989 1528.68 489.021 1532.27 475.448L1641.7 39.1189C1648.88 16.3643 1667.65 0.79541 1690.42 0.79541H2261.09C2276.66 0.79541 2290.64 7.98107 2301.02 19.9572C2309.81 32.7317 2313 48.3006 2309.81 63.4703Z"
        fill="#7348F6"
      />
      <path
        d="M1330.47 964.073L1269.77 1196.81C1262.58 1224.75 1235.02 1247.11 1207.07 1247.11H792.942C764.988 1247.11 747.816 1224.35 754.605 1196.81L817.303 964.073C824.491 936.129 852.046 913.774 880 913.774H1292.13C1320.08 913.774 1337.26 936.129 1330.47 964.073ZM1565.29 63.4703L1385.98 774.452C1380.79 797.206 1361.62 812.775 1338.85 812.775H924.728C909.153 812.775 893.579 805.59 884.793 793.613C876.007 781.637 867.621 752.096 909.153 722.156L1100.04 583.233C1120.41 568.463 1110.03 536.127 1084.87 536.127H836.471C822.494 536.127 806.92 528.942 798.134 516.965C787.751 504.989 784.157 489.021 787.751 475.448L897.173 39.1189C904.361 16.3643 923.13 0.79541 945.893 0.79541H1516.56C1532.14 0.79541 1546.12 7.98107 1556.5 19.9572C1565.29 32.7317 1568.48 48.3006 1565.29 63.4703Z"
        fill="#7348F6"
      />
      <path
        d="M577.345 964.073L516.644 1196.81C509.456 1224.75 481.901 1247.11 453.946 1247.11H39.8201C11.8656 1247.11 -5.30645 1224.35 1.4825 1196.81L64.1805 964.073C71.3688 936.129 98.9239 913.774 126.878 913.774H539.008C566.962 913.774 584.134 936.129 577.345 964.073ZM812.163 63.4703L632.855 774.452C627.664 797.206 608.495 812.775 585.732 812.775H171.606C156.031 812.775 140.456 805.59 131.671 793.613C122.885 781.637 114.499 752.096 156.031 722.156L346.92 583.233C367.287 568.463 356.904 536.127 331.745 536.127H83.3494C69.3721 536.127 53.7975 528.942 45.0118 516.965C34.6287 504.989 31.0345 489.021 34.6286 475.448L144.05 39.1189C151.239 16.3643 170.008 0.79541 192.771 0.79541H763.443C779.017 0.79541 792.995 7.98107 803.378 19.9572C812.163 32.7317 815.358 48.3006 812.163 63.4703Z"
        fill="#7348F6"
      />
    </svg>
  )
}
