import { ConnectKitButton } from 'connectkit'
import { AddressHash } from 'utils/api'
import WalletButton from '../WalletButton'

type Props = {
  onAccountClick?(): void
}

function WalletConnectButton({ onAccountClick }: Props) {
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, address, ensName, show }) => (
        <WalletButton
          address={
            isConnected ? (address as AddressHash | undefined) : undefined
          }
          ensName={isConnected ? ensName : undefined}
          onConnectWalletClick={show}
          onAccountClick={onAccountClick}
        />
      )}
    </ConnectKitButton.Custom>
  )
}

export default WalletConnectButton
