import { memo, useCallback } from 'react'
import Link from 'next/link'
import Avatar from 'components/Avatar'
import { MintStats, ProfileSlug } from 'utils/api'
import classNames from 'utils/classnames'
import { s } from 'utils/helpers'

type ProfileResultProps = {
  index: number
  name: string
  slug: ProfileSlug
  mintStats: MintStats
  isFocused?: boolean
  onMouseOver: (index: number) => void
  onClose: () => void
}

const ProfileResultRow = ({
  slug,
  name,
  isFocused,
  index,
  onClose,
  onMouseOver,
  mintStats: { totalMints, totalProjects },
}: ProfileResultProps) => {
  const onHandleMouseOver = useCallback(
    () => onMouseOver(index),
    [onMouseOver, index],
  )

  return (
    <Link href={`/profile/${slug}`} onClick={onClose}>
      <div
        className={classNames(
          'flex flex-row items-center gap-4 py-4',
          isFocused === true && 'font-bold',
        )}
        onMouseOver={onHandleMouseOver}
      >
        <div>
          <Avatar slug={slug} size={32} />
        </div>
        <div className="flex flex-col md:items-center md:flex-row">
          <span>
            <span className="font-bold">{name}</span>
            <>
              {BigInt(totalMints) > 0n &&
                ` • ${BigInt(totalMints).toLocaleString()} mint${s(
                  BigInt(totalMints),
                )}`}
              {totalProjects > 0 &&
                ` • ${totalProjects.toLocaleString()} project${s(
                  totalProjects,
                )}`}
            </>
          </span>
        </div>
      </div>
    </Link>
  )
}

export default memo(ProfileResultRow)
