import React, { useMemo } from 'react'
import { createContext } from '@fluentui/react-context-selector'
import { Flags, useAPIResponse } from 'utils/api'

interface Props {
  children: React.ReactNode
}

type ContextValue = Flags

export const Context = createContext<ContextValue>({})
const Provider = Context.Provider

// this is used to provide feature flags across the entire app (above any layout)
// NB: these aren't available during SSR
function FeatureFlagsProvider({ children }: Props) {
  const { data: flagsData } = useAPIResponse<Flags>('mintfun/flags')
  const flags: ContextValue = useMemo(() => flagsData ?? {}, [flagsData])
  return <Provider value={flags}>{children}</Provider>
}

export default React.memo(FeatureFlagsProvider)
