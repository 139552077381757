import { useCallback, useState } from 'react'
import { assertIsObject } from 'assertate'
import { GetServerSidePropsContext, NextPageContext } from 'next'
import qs from 'qs'
import { defaultChain } from 'utils/chains'
import { mutateRead } from 'utils/swr'
import {
  AddressHash,
  ChainId,
  Contract,
  Profile,
  ProfileId,
  ProfileSlug,
  contractEndpoint,
} from '.'
import { clientRetry } from './client'

type QueryParams<T> = {
  [P in keyof T]: T[P]
}

type QueryString = '' | `?${string}`

type RouteWithQueryParams<Route extends string> = `${Route}${QueryString}`

export const withParams = <Route extends string, T>(
  route: Route,
  params: QueryParams<T>,
): RouteWithQueryParams<Route> => {
  const queryString = qs.stringify(params, {
    addQueryPrefix: true,
  })
  return `${route}${queryString}` as RouteWithQueryParams<Route>
}

export const apiURL = (): string => {
  // use the API_URL if we're on the backend
  // use the next-prefixed url's if available otherwise
  const apiUrlBackend = process.env.API_URL

  if (apiUrlBackend !== undefined) {
    return `${apiUrlBackend}/api`
  }

  if (typeof window === 'undefined') {
    throw new Error('missing API_URL env on backend')
  }

  return `/api`
}

export const getAvatarAPIURL = (slug: ProfileSlug, width?: number): string => {
  const base = `${apiURL()}/avatar/${slug}?format=auto&crop=1%3A1`

  if (width !== undefined) {
    return `${base}&width=${width}`
  }

  return base
}

export const getCollectionAvatarAPIURL = (
  contract: Contract,
  width?: number,
): string => {
  const base = `${apiURL()}/collection_avatar/${contract}?format=auto&crop=1%3A1`
  if (width !== undefined) {
    return `${base}&width=${width}`
  }

  return base
}

export async function fetcher(
  url: string,
  ctx?: GetServerSidePropsContext | NextPageContext,
) {
  const r = await clientRetry('GET', url, null, ctx)
  if (!r.ok) {
    throw new Error(`Failed Response: status=${r.status}`)
  }
  return r.json()
}

export function getProfileFromArrayIfExists(
  profileID: ProfileId,
  profiles: Profile[],
): Profile | undefined {
  return profiles.find(({ id }) => id === profileID)
}

export function getProfileFromArray(
  profileID: ProfileId,
  profiles: Profile[],
  variableName?: string,
): Profile {
  const profile = getProfileFromArrayIfExists(profileID, profiles)
  try {
    assertIsObject(profile, variableName ?? 'profile')
    return profile
  } catch (e) {
    const err = e as Error
    // provided for sentry
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    err.profileID = profileID
    throw err
  }
}

export function slugsAreEqual(
  slug1: ProfileSlug,
  slug2: ProfileSlug | undefined,
): boolean {
  return slug1.toLowerCase() === slug2?.toLowerCase()
}

export const usePrefetchCollection = () => {
  const [hoveredRows, hoveredRowsSet] = useState<Record<string, boolean>>({})

  const onHover = useCallback(
    (contract: Contract) => {
      if (hoveredRows[contract]) {
        return
      }

      hoveredRowsSet((prev) => ({ ...prev, [contract]: true }))

      mutateRead(contractEndpoint({ contract }))
    },
    [hoveredRows],
  )

  return onHover
}

export const makeContract = ({
  address,
  chainId = defaultChain.id,
}: {
  address: AddressHash
  chainId?: ChainId
}): Contract => {
  return `${chainId}:${address}` as Contract
}

export const getAddress = (contract: Contract): AddressHash => {
  if (!contract.includes(':')) {
    throw new Error(`getAddress: ${contract} is not a contract`)
  }
  const [, address] = contract.split(':')
  return address as AddressHash
}

export const getChainId = (contract: Contract): ChainId => {
  if (!contract.includes(':')) {
    throw new Error(`getChainId: ${contract} is not a contract`)
  }
  const [chainId] = contract.split(':')
  return parseInt(chainId, 10) as ChainId
}

export const isContractEqual = (a: Contract, b: Contract): boolean =>
  getChainId(a) === getChainId(b) &&
  getAddress(a).toLowerCase() === getAddress(b).toLowerCase()
