import { ChainId } from 'utils/api'
import { supportedChains } from 'utils/chains'
import { assertUnreachable } from 'utils/typescript'
import BlockscoutLogo from './icons/BlockscoutLogo'
import EtherscanLogo from './icons/EtherscanLogo'

type Props = {
  chainId: ChainId
  className?: string
}

export const BlockExplorerLogo = ({ chainId, ...props }: Props) => {
  const chain = supportedChains.find((chain) => chain.id === chainId)
  if (chain === undefined) {
    return null
  }

  switch (chain.blockExplorer.host) {
    case 'etherscan':
      return <EtherscanLogo {...props} />
    case 'blockscout':
      return <BlockscoutLogo {...props} />
    default:
      assertUnreachable(chain.blockExplorer.host)
  }
}
