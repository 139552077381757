import { Hash } from 'viem'
import { withParams } from './helpers'
import {
  AddressHash,
  ChainId,
  Contract,
  OfferSortType,
  ProfileSlug,
} from './types'

export const contractABIEndpoint = ({ contract }: { contract: Contract }) => {
  return `mintfun/contract/${contract}/abi` as const
}

export const contractSampleNFTsEndpoint = ({
  contract,
}: {
  contract: Contract
}) => {
  return `mintfun/contract/${contract}/sample-nfts` as const
}

export const contractPendingTransactionsEndpoint = ({
  contract,
}: {
  contract: Contract
}) => {
  return `mintfun/contract/${contract}/pending-txs` as const
}

export const contractMintCountEndpoint = ({
  contract,
  address,
}: {
  contract: Contract
  address?: AddressHash
}) => {
  return `mintfun/contract/${contract}/mint-count${
    address ? `?address=${address}` : ''
  }` as const
}

export const contractTokensEndpoint = ({
  contract,
  address,
}: {
  contract: Contract
  address: AddressHash | ''
}) => {
  const route = `mintfun/contract/${contract}/tokens` as const
  return withParams(route, { address })
}

export const contractEndpoint = ({ contract }: { contract: Contract }) => {
  return `mintfun/contract/${contract}` as const
}

export const collectionDetailsEndpoint = ({
  contract,
}: {
  contract: Contract
}) => {
  return `mintfun/contract/${contract}/details` as const
}

export const contractMintTimesEndpoint = ({
  contract,
}: {
  contract: Contract
}) => {
  return `mintfun/contract/${contract}/mint-times` as const
}

export const contractSocialProofEndpoint = ({
  contract,
  address,
}: {
  contract: Contract
  address: AddressHash
}) => {
  const route = `mintfun/contract/${contract}/social-proof` as const
  return withParams(route, { address })
}

export const contractAuthorizedEndpoint = ({
  contract,
  address,
}: {
  contract: Contract
  address: AddressHash
}) => {
  const route = `mintfun/contract/${contract}/authorized` as const
  return withParams(route, { address })
}

export const contractMintedEndpoint = ({
  contract,
  minterAddress,
}: {
  contract: Contract
  minterAddress?: AddressHash
}) => {
  const route = `mintfun/contract/${contract}/minted` as const
  return withParams(route, { address: minterAddress })
}

export const contractMintStatusEndpoint = ({
  contract,
  minterAddress,
}: {
  contract: Contract
  minterAddress?: string
}) => {
  const route = `mintfun/contract/${contract}/mint-status` as const
  return withParams(route, { address: minterAddress })
}

export const contractTransactionsEndpoint = ({
  contract,
  minterAddress,
}: {
  contract: Contract
  minterAddress?: string
}) => {
  const route = `mintfun/contract/${contract}/transactions` as const
  return withParams(route, { address: minterAddress })
}

export const contractMetaImageUrl = ({
  contract,
  minterAddress,
}: {
  contract: Contract
  minterAddress?: string
}) => {
  const route =
    `https://mint.fun/api/mintfun/contract/${contract}/meta-image` as const
  return withParams(route, { minter: minterAddress })
}

export const profileMetaImageUrl = (slug: ProfileSlug) => {
  return `https://mint.fun/api/mintfun/profile/${slug}/meta-image` as const
}

export const nftThumbnailEndpoint = ({
  contract,
  tokenId,
  width,
  height,
}: {
  contract: Contract
  tokenId: string
  width?: number
  height?: number
}) => {
  const route = `/api/nft/${contract}/${tokenId}` as const
  return withParams(route, { format: 'auto', crop: '1:1', width, height })
}

export const reservoirEndpoint = (origin: string, chainId: number) => {
  return `${origin}/api/mintfun/reservoir/chain/${chainId}` as const
}

export const mediaUploadEndpoint = '/api/mintfun/upload'
export const createMediaUploadEndpoint = '/api/mintfun/create/media-upload'

export const legacyExportCtxFollowingEndpoint =
  '/api/legacy/export-ctx-following'

export const profileProjectsEndpoint = ({ slug }: { slug: ProfileSlug }) =>
  `mintfun/profile/${slug}/deployed` as const

export const fundropPointsEndpoint = ({
  address,
}: {
  address: AddressHash | ''
}) => {
  const route = `mintfun/fundrop/points` as const
  return withParams(route, { address })
}

export const fundropPassEndpoint = ({
  address,
}: {
  address: AddressHash | ''
}) => {
  const route = `mintfun/fundrop/pass` as const
  return withParams(route, { address })
}

export const fundropLevelsEndpoint = ({
  address,
}: {
  address: AddressHash | ''
}) => {
  const route = `mintfun/fundrop/level` as const
  return withParams(route, { address })
}

export const fundropS1MintEndpoint = ({
  address,
}: {
  address: AddressHash | ''
}) => {
  const route = `mintfun/fundrop/season1/mint` as const
  return withParams(route, { address })
}

export const fundropLeaderboardEndpoint = ({
  address,
  sort,
}: {
  address: AddressHash | ''
  sort: 'points' | 'streak'
}) => {
  const route = `mintfun/fundrop/leaderboard`
  return withParams(route, { address, sort })
}

export const profileOffersEndpoint = ({
  slug,
  sort,
  chainId,
}: {
  slug: ProfileSlug
  sort: OfferSortType
  chainId: ChainId
}) => {
  const route = `mintfun/profile/${slug}/offers/${chainId}` as const
  return withParams(route, { sort })
}

export const profileOffersCountEndpoint = ({
  slug,
  chainId,
}: {
  slug: ProfileSlug | ''
  chainId: ChainId
}) => {
  return `mintfun/profile/${slug}/offer-count/${chainId}` as const
}

export const profileFollowingEndpoint = ({ slug }: { slug: ProfileSlug }) => {
  return `mintfun/profile/${slug}/following` as const
}

export const profileFollowersEndpoint = ({ slug }: { slug: ProfileSlug }) => {
  return `mintfun/profile/${slug}/followers` as const
}

export const profileEndpoint = ({ slug }: { slug: ProfileSlug }) => {
  return `mintfun/profile/${slug}` as const
}

export const profileMintsEndpoint = ({
  slug,
  mfOnly,
}: {
  slug: ProfileSlug
  mfOnly?: boolean
}) => {
  const route = `mintfun/profile/${slug}/mints` as const
  return withParams(route, { mfOnly: mfOnly === true ? 1 : undefined })
}

export const searchSuggestionsEndpoint = 'mintfun/search/suggestions'

export const searchEndpoint = ({ query }: { query: string }) => {
  const route = 'mintfun/search' as const
  return withParams(route, { q: query })
}

export const featuredProjectEndpoint = 'mintfun/featured-project'

export const accountEndpoint = 'mintfun/account'

export const interactionEndpoint = 'mintfun/interaction'

export const authEmailStateEndpoint = 'mintfun/auth/email-state'

export const submitBridgeTransactionEndpoint = 'mintfun/bridge/submit'

export const funContractCreatedEndpoint = ({
  chainId,
  transactionHash,
}: {
  chainId: ChainId
  transactionHash: Hash
}) => {
  return `mintfun/create/${transactionHash}/created/${chainId}` as const
}

export const activityCollectionsEndpoint = ({
  address,
}: {
  address: AddressHash | ''
}) => {
  return `mintfun/create/${address}/activity` as const
}

export const relatedProjectsEndpoint = ({ contract }: { contract: Contract }) =>
  `mintfun/contract/${contract}/related` as const

export const fundropFinaleMint = ({
  address,
}: {
  address: AddressHash | undefined
}) => {
  const route = `mintfun/fundrop/finale-mint` as const
  return withParams(route, { address })
}
