import { memo, useCallback } from 'react'
import Link from 'next/link'
import { Contract, contractEndpoint } from 'utils/api'
import { getContractPath } from 'utils/chains'
import classNames from 'utils/classnames'
import { mutateRead } from 'utils/swr'

type Props = {
  name: string
  contract: Contract
  onSuggestionClick: () => void
}

const SearchSuggestion = ({ name, contract, onSuggestionClick }: Props) => {
  const handleMouseOver = useCallback(() => {
    // prefetch on hover
    mutateRead(contractEndpoint({ contract }))
  }, [contract])

  return (
    <Link
      href={getContractPath(contract)}
      onClick={onSuggestionClick}
      onMouseOver={handleMouseOver}
      className={classNames(
        'flex justify-center items-center h-10 px-3.5 rounded-xl whitespace-nowrap',
        'text-white hover:text-black',
        'bg-black dark:bg-neutral-700',
        'hover:bg-neutral-300 dark:hover:bg-white',
        'normal-case',
      )}
    >
      {name.trim() !== '' ? name : 'Unidentified contract'}
    </Link>
  )
}

export default memo(SearchSuggestion)
