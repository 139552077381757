import { SVGAttributes } from 'react'

export default function CloseIcon(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.2 6.4a1.4 1.4 0 111.4 1.4h-1.4V6.4zM13.4 10.6V9.48c0-.928.752-1.68 1.68-1.68h1.12v1.12a1.68 1.68 0 01-1.68 1.68H13.4zM13.4 13.4v-2.8h-2.8V9.48A1.68 1.68 0 008.92 7.8H7.8V6.4a1.4 1.4 0 10-1.4 1.4h1.4v1.12c0 .928.752 1.68 1.68 1.68h1.12v2.8H9.48a1.68 1.68 0 00-1.68 1.68v1.12H6.4a1.4 1.4 0 101.4 1.4v-1.4h1.12a1.68 1.68 0 001.68-1.68V13.4h2.8z"
      ></path>
      <path
        fill="currentColor"
        d="M16.2 16.2h-1.12a1.68 1.68 0 01-1.68-1.68V13.4h1.12c.928 0 1.68.752 1.68 1.68v1.12zM16.2 16.2h1.4a1.4 1.4 0 11-1.4 1.4v-1.4z"
      ></path>
    </svg>
  )
}
