import { useMemo } from 'react'
import { useBreakpoints } from 'react-match-breakpoints'
import {
  SearchSuggestionsResponse,
  searchSuggestionsEndpoint,
  useAPIResponse,
} from 'utils/api'

const useSearchSuggestions = () => {
  const bp = useBreakpoints()
  const { data: suggestionsRes } = useAPIResponse<SearchSuggestionsResponse>(
    searchSuggestionsEndpoint,
  )

  const suggestions = useMemo(() => {
    if (suggestionsRes === undefined) {
      return
    }

    const suggestions = [...suggestionsRes.suggestions]

    return suggestions.splice(0, bp.md ? 15 : 8)
  }, [suggestionsRes, bp.md])

  return suggestions
}

export default useSearchSuggestions
