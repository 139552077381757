import { ReactNode, useCallback, useMemo, useState } from 'react'
import { createContext } from '@fluentui/react-context-selector'
import { CollectionSettings } from 'utils/api'

interface Props {
  children: ReactNode
}

export type ContextValue = {
  sidebarOpen: boolean
  toggleSidebar: () => void
  closeSidebar: () => void
  settingsOverride?: CollectionSettings
  setSettingsOverride: (settings: CollectionSettings | undefined) => void
}

export const LayoutContext = createContext<ContextValue>(
  undefined as unknown as ContextValue,
)
const Provider = LayoutContext.Provider

// this is used to provide the app info about sidebar state
function LayoutProvider({ children }: Props) {
  const [sidebarOpen, sidebarOpenSet] = useState(false)
  const toggleSidebar = useCallback(() => sidebarOpenSet((prev) => !prev), [])
  const closeSidebar = useCallback(() => sidebarOpenSet(false), [])
  const [settingsOverride, settingsOverrideSet] = useState<
    CollectionSettings | undefined
  >(undefined)

  const value = useMemo<ContextValue>(
    () => ({
      sidebarOpen,
      toggleSidebar,
      closeSidebar,
      settingsOverride,
      setSettingsOverride: settingsOverrideSet,
    }),
    [closeSidebar, settingsOverride, sidebarOpen, toggleSidebar],
  )

  return <Provider value={value}>{children}</Provider>
}

export default LayoutProvider
