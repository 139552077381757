import { ReactNode } from 'react'
import { Hash } from 'viem'
import { base, baseGoerli } from 'viem/chains'
import { AddressHash, ChainId, Contract, getChainId } from 'utils/api'
import { Chain } from 'utils/chains'
import importRetry from 'utils/importRetry'
import { lazy } from 'utils/lazy'
import BridgeConfirmedToast from './BridgeConfirmedToast'
import CustomToast from './CustomToast'
import EmailSignupToast from './EmailSignupToast'
import PartnerMintSuccessToast from './PartnerMintSuccessToast'
import TransactionToast from './TransactionToast'
import ViewTransactionOnBlockExplorerToast from './ViewTransactionOnBlockExplorerToast'

const defaultToastDismissTime = 5000
// don't dismiss tx toasts automatically
const viewTransactionInfoOnEtherscanDismissTime = Infinity
const bridgeConfirmedToastDismissTime = Infinity
const transactionToastDismissTime = Infinity
const emailToastDismissTime = Infinity

export const ToastContainer = lazy({
  loader: () => import('./ToastContainer'),
  Fallback: () => null,
})

export const toastLibrary = async () =>
  importRetry(() => import('react-hot-toast'))

export async function dismissToast(toastId: string | undefined) {
  if (toastId === undefined) {
    return
  }

  toastLibrary().then(({ toast }) => {
    toast.dismiss(toastId)
  })
}

export function createToast(message: ReactNode) {
  toastLibrary().then(({ toast }) => {
    toast.custom(
      (t) => (
        <CustomToast toast={t} fixedWidth showCloseButton>
          {message}
        </CustomToast>
      ),
      { duration: defaultToastDismissTime },
    )
  })
}

export async function createCustomToast(
  content: React.ReactNode,
  duration: number = defaultToastDismissTime,
  id?: string,
  onDismiss?: () => void,
) {
  try {
    const { toast } = await toastLibrary()
    const toastId = toast.custom(
      (t) => (
        <CustomToast toast={t} fixedWidth showCloseButton onDismiss={onDismiss}>
          {content}
        </CustomToast>
      ),
      {
        duration,
        id,
      },
    )
    return toastId
  } catch (e) {
    console.error('error loading toast', e)
  }
}

export const createErrorToast = (title: string, error: Error) => {
  createToast(
    `${title}: ${error.message ?? error.toString() ?? 'Unknown error'}`,
  )
}

export function createInsufficientFundsToast() {
  createToast('Insufficient funds')
}

export function createNetworkMismatchToast(chainName: Chain['name']) {
  createToast(
    <>
      Please connect to the <span className="normal-case">{chainName}</span>{' '}
      network in your wallet
    </>,
  )
}

export function createNetworkNotSupportedToast(targetChainId: number) {
  createToast(`Target Chain ID ${targetChainId} is not supported`)
}

export async function createTransactionPendingToast(
  chainId: ChainId,
  transactionHash: string,
): Promise<string | undefined> {
  try {
    const { toast } = await toastLibrary()
    const toastId = toast.custom(
      (t) => (
        <ViewTransactionOnBlockExplorerToast
          chainId={chainId}
          toast={t}
          transactionHash={transactionHash}
          text={`Transaction pending...`}
        />
      ),
      { duration: viewTransactionInfoOnEtherscanDismissTime },
    )
    return toastId
  } catch (e) {
    console.error('error loading toast', e)
    return undefined
  }
}

const bridgeTransactionToastDurationOverride: Partial<Record<ChainId, string>> =
  {
    [base.id]: 'about 2 minutes',
    [baseGoerli.id]: 'about 2 minutes',
  }

export async function createBridgeTransactionPendingToast(
  transactionHash: Hash,
  chain: ChainId,
  destinationChainId: ChainId,
): Promise<string | undefined> {
  try {
    const { toast } = await toastLibrary()
    const toastId = toast.custom(
      (t) => (
        <ViewTransactionOnBlockExplorerToast
          chainId={chain}
          toast={t}
          transactionHash={transactionHash}
          text={`Bridge transaction pending... This may take ${
            bridgeTransactionToastDurationOverride[destinationChainId] ??
            'about a minute'
          }`}
        />
      ),
      { duration: viewTransactionInfoOnEtherscanDismissTime },
    )
    return toastId
  } catch (e) {
    console.error('error loading toast', e)
    return undefined
  }
}

export async function createBridgeConfirmedToast(
  tx: Hash,
  destinationChain: Chain,
  transactionChain: Chain,
  newBalance: bigint,
  toastId?: string,
) {
  const { toast } = await toastLibrary()
  if (toastId !== undefined) {
    toast.dismiss(toastId)
  }

  toast.custom(
    (t) => (
      <BridgeConfirmedToast
        toast={t}
        tx={tx}
        destinationChain={destinationChain}
        transactionChain={transactionChain}
        newBalance={newBalance}
      />
    ),
    {
      duration: bridgeConfirmedToastDismissTime,
    },
  )
}

export async function createEmailSignupToast(
  handleCheckpointReached: (() => void) | undefined,
) {
  const { toast } = await toastLibrary()

  toast.custom(
    (t) => <EmailSignupToast toast={t} onDismiss={handleCheckpointReached} />,
    {
      id: 'email-toast', // prevent duplicate toasts
      duration: emailToastDismissTime,
    },
  )
}

export async function createTransactionToast({
  tokenIds,
  transactionHash,
  collectionContract,
  collectionName,
  isFunContract,
  minterAddress,
  toastId,
  skipTokenIdCheck = false,
}: {
  tokenIds: string[]
  transactionHash: string
  collectionContract: Contract
  collectionName?: string
  isFunContract: boolean
  minterAddress?: AddressHash
  toastId?: string
  skipTokenIdCheck?: boolean
}) {
  const { toast } = await toastLibrary()
  if (toastId !== undefined) {
    // remove with animation (can be removed without animation using `remove()`)
    toast.dismiss(toastId)
  }

  if (skipTokenIdCheck || tokenIds.length > 0) {
    toast.custom(
      (t) => (
        <TransactionToast
          toast={t}
          tokenIds={tokenIds}
          collectionContract={collectionContract}
          collectionName={collectionName}
          minterAddress={minterAddress}
          isFunContract={isFunContract}
        />
      ),
      { duration: transactionToastDismissTime },
    )
    return
  }

  toast.custom(
    (t) => (
      <ViewTransactionOnBlockExplorerToast
        chainId={getChainId(collectionContract)}
        toast={t}
        transactionHash={transactionHash}
        text={`We encountered something unexpected with your ${
          collectionName !== undefined ? `${collectionName} ` : ''
        }mint`}
      />
    ),
    { duration: viewTransactionInfoOnEtherscanDismissTime },
  )
}

export async function createPartnerMintSuccessToast(
  tokenIds: string[],
  transactionHash: string,
  collectionContract: Contract,
  collectionName: string | undefined,
  minterAddress: AddressHash | undefined,
  toastId?: string,
) {
  const { toast } = await toastLibrary()

  if (toastId !== undefined) {
    toast.dismiss(toastId)
  }

  toast.custom(
    (t) => (
      <PartnerMintSuccessToast
        toast={t}
        tokenIds={tokenIds}
        transactionHash={transactionHash}
        collectionContract={collectionContract}
        collectionName={collectionName}
        minterAddress={minterAddress}
      />
    ),
    { duration: transactionToastDismissTime },
  )
}
