import { SVGProps } from 'react'

export default function Hourglass(props: Partial<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6 2.706c0-.39.316-.706.706-.706h11.53c.39 0 .705.316.705.706v1.059a.588.588 0 01-.588.588.588.588 0 00-.588.588V9.53c0 .39-.316.706-.706.706h-.47V5.06a.706.706 0 00-.706-.706H9.059a.706.706 0 00-.706.706v5.176h-.47a.706.706 0 01-.706-.706V4.941a.588.588 0 00-.589-.588A.588.588 0 016 3.765v-1.06zM9.53 11.412h-.471a.706.706 0 01-.706-.706v-.47h.47c.39 0 .707.315.707.705v.47zM9.53 13.765v-2.353H10c.39 0 .706.316.706.706v.94c0 .39-.316.707-.706.707h-.47zM8.353 14.941v-.47c0-.39.316-.706.706-.706h.47v.47c0 .39-.316.706-.705.706h-.47z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.53 18.47h-.471a.706.706 0 01-.706-.705V14.94h-.47a.706.706 0 00-.706.706v3.412a.588.588 0 01-.589.588.588.588 0 00-.588.588v1.06c0 .389.316.705.706.705h11.53c.39 0 .705-.316.705-.706v-1.059a.588.588 0 00-.588-.588.588.588 0 01-.588-.588v-3.412a.706.706 0 00-.706-.706h-.47v-.47a.706.706 0 00-.706-.706h-.471v-2.353h.47c.39 0 .706-.316.706-.706v-.47h-.47a.706.706 0 00-.706.705v.47h-.47a.706.706 0 00-.707.707v.94c0 .39.317.707.706.707h.47v.47c0 .39.317.706.707.706h.47v2.824c0 .39-.316.706-.706.706h-.47V18a.706.706 0 00-.706-.706h-.47v-.47a.706.706 0 00-.707-.706h-.47v-.589a.588.588 0 00-1.176 0v.589h-.471a.706.706 0 00-.706.706v.47h-.47A.706.706 0 009.53 18v.47zm1.176 0H9.53v.589a.588.588 0 101.176 0v-.588zm1.177 0h-1.177v-1.176h1.177v1.177zm1.176 0v.589a.588.588 0 01-1.176 0v-.588h1.176zm0-1.176h1.176v1.177H13.06v-1.177zm0 0h-1.176v-1.176h1.176v1.176zm1.176 1.177h1.177v.588a.588.588 0 11-1.177 0v-.588z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
