import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { Spinner } from 'components/icons/Spinner'

const sharedClassNames =
  'select-none py-3 px-4 rounded-md group group-aria aria-disabled:pointer-events-none aria-busy:pointer-events-none aria-disabled:opacity-50 aria-busy:opacity-50'

const variants = {
  primary: twMerge(
    sharedClassNames,
    'text-white hover:text-black dark:hover:text-white',
    'bg-black dark:bg-neutral-750',
    'hover:bg-neutral-300',
    'dark:hover:bg-neutral-400',
    'font-bold',
  ),
  heavy: twMerge(
    sharedClassNames,
    'text-white hover:text-black dark:hover:text-white',
    'bg-black dark:bg-neutral-750',
    'hover:bg-neutral-300',
    'dark:hover:bg-neutral-300 dark:hover:text-neutral-750',
    'font-normal',
  ),
  heavyModal: twMerge(
    sharedClassNames,
    'text-white hover:text-black dark:hover:text-white',
    'bg-black dark:bg-neutral-800',
    'dark:backdrop-blur-sm',
    'hover:bg-neutral-300',
    'dark:hover:bg-neutral-300 dark:hover:text-neutral-800',
    'font-normal',
  ),
  raised: twMerge(
    sharedClassNames,
    'text-white hover:text-black dark:hover:text-white',
    'bg-black dark:bg-neutral-800',
    'hover:bg-neutral-300',
    'dark:hover:bg-neutral-300 dark:hover:text-neutral-800',
    'font-bold',
  ),
  outline: twMerge(
    sharedClassNames,
    'text-black dark:text-white',
    'border-2 border-neutral-750',
    'font-bold',
  ),
  outlineGreen: twMerge(
    sharedClassNames,
    'text-black dark:text-[#A5F0C0]',
    'border-2 border-[#A5F0C0] shadow-[0_1px_21px_0_#A5F0C0]',
    'font-bold',
  ),
  filterDefault: twMerge(
    sharedClassNames,
    'text-black dark:text-white hover:text-black dark:hover:text-white',
    'bg-neutral-100 dark:bg-neutral-800',
    'hover:bg-neutral-300 dark:hover:bg-neutral-750',
    'font-bold text-lg',
  ),
  filterSelected: twMerge(
    sharedClassNames,
    'text-white dark:text-neutral-900',
    'bg-black dark:bg-neutral-300',
    'font-bold text-lg',
  ),
  brandPurple: twMerge(
    sharedClassNames,
    'text-white',
    'bg-fun-purple hover:bg-fun-purple',
    'font-bold',
  ),
  inverted: twMerge(
    sharedClassNames,
    'text-white dark:text-black dark:hover:text-white hover:text-black',
    'bg-neutral-800 dark:bg-white dark:hover:bg-neutral-800 hover:bg-white',
    'font-bold',
  ),
}

export type Variant = keyof typeof variants

type PropsWithLabel = {
  label: NonNullable<ReactNode>
  secondaryLabel?: ReactNode
  ariaLabel?: string
  iconPosition?: 'start' | 'end'
  className?: string
} & ({ icon: ReactNode } | { icon?: ReactNode })

type SharedProps = {
  children?: never
  variant?: Variant
  disabled?: boolean
  pending?: boolean
  labelClassName?: string
} & PropsWithLabel

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & SharedProps

type ButtonLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  SharedProps & {
    href: string
    shallow?: boolean
  }

type ButtonAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  SharedProps & {
    href: string
  }

const ButtonLabel = ({
  label,
  secondaryLabel,
  icon,
  iconPosition,
  className,
}: PropsWithLabel) => {
  return (
    <span className="grid">
      <span
        className={twMerge(
          'row-span-full col-span-full flex gap-2 items-center group-aria-busy:opacity-0 whitespace-nowrap',
          iconPosition === 'end' ? 'flex-row-reverse' : null,
          secondaryLabel === undefined ? 'justify-center text-center' : null,
          className,
        )}
      >
        {icon != null ? <span aria-hidden>{icon}</span> : null}
        {secondaryLabel !== undefined ? (
          <span className="flex items-center justify-between flex-1">
            <span className="flex-items-center">{label}</span>
            <span className="flex items-center pl-6 pr-3">
              {secondaryLabel}
            </span>
          </span>
        ) : (
          label
        )}
      </span>
      <span
        className="row-span-full col-span-full hidden group-aria-busy:flex items-center justify-center"
        aria-hidden
      >
        <Spinner />
      </span>
    </span>
  )
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      disabled = false,
      pending = false,
      label,
      ariaLabel,
      labelClassName,
      icon = null,
      iconPosition = 'start',
      type = 'button',
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled || pending}
        aria-disabled={disabled}
        aria-busy={pending}
        aria-label={ariaLabel ?? label.toString()}
        className={twMerge(variants[variant], className)}
        {...props}
      >
        <ButtonLabel
          label={label}
          className={labelClassName}
          icon={icon}
          iconPosition={iconPosition}
        />
      </button>
    )
  },
)

Button.displayName = 'Button'

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      variant = 'primary',
      disabled = false,
      pending = false,
      label,
      ariaLabel,
      labelClassName,
      icon = null,
      iconPosition = 'start',
      href,
      target,
      rel = target === '_blank' ? 'noopener noreferrer' : undefined,
      className,
      shallow = false,
      ...props
    },
    ref,
  ) => {
    return (
      <Link
        href={href}
        shallow={shallow}
        ref={ref}
        target={target}
        rel={rel}
        aria-disabled={disabled}
        aria-busy={pending}
        aria-label={ariaLabel ?? label.toString()}
        className={twMerge(
          variants[variant],
          // Link doesn't center
          'flex items-center justify-center',
          className,
        )}
        {...props}
      >
        <ButtonLabel
          label={label}
          className={labelClassName}
          icon={icon}
          iconPosition={iconPosition}
        />
      </Link>
    )
  },
)

ButtonLink.displayName = 'ButtonLink'

export const ButtonAnchor = forwardRef<HTMLAnchorElement, ButtonAnchorProps>(
  (
    {
      variant = 'primary',
      disabled = false,
      pending = false,
      label,
      ariaLabel,
      labelClassName,
      icon = null,
      iconPosition = 'start',
      href,
      target,
      rel = target === '_blank' ? 'noopener noreferrer' : undefined,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <a
        href={href}
        ref={ref}
        target={target}
        rel={rel}
        aria-disabled={disabled}
        aria-busy={pending}
        aria-label={ariaLabel ?? label.toString()}
        className={twMerge(
          variants[variant],
          // anchor tag doesn't center
          'flex items-center justify-center',
          className,
        )}
        {...props}
      >
        <ButtonLabel
          label={label}
          className={labelClassName}
          icon={icon}
          iconPosition={iconPosition}
        />
      </a>
    )
  },
)

ButtonAnchor.displayName = 'ButtonAnchor'

type ButtonDivProps = { className: string } & SharedProps

export const ButtonDiv = forwardRef<HTMLDivElement, ButtonDivProps>(
  (
    {
      variant = 'primary',
      label,
      secondaryLabel,
      ariaLabel,
      disabled = false,
      pending = false,
      labelClassName,
      icon = null,
      iconPosition = 'start',
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        aria-disabled={disabled}
        aria-busy={pending}
        aria-label={ariaLabel ?? label.toString()}
        className={twMerge(variants[variant], className)}
        {...props}
      >
        <ButtonLabel
          label={label}
          secondaryLabel={secondaryLabel}
          className={labelClassName}
          icon={icon}
          iconPosition={iconPosition}
        />
      </div>
    )
  },
)

ButtonDiv.displayName = 'ButtonDiv'
