import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useAsync } from '@react-hook/async'
import { Variant as BtnVariant, Button } from 'components/Button'
import TextInput, { Variant as TextInputVariant } from 'components/TextInput'
import { useOpenAuthFlow } from 'providers/Auth'
import { sendEmail } from 'utils/api/auth/utils'
import classNames from 'utils/classnames'
import { createToast } from 'utils/toasts'
import { useAccount } from 'utils/useAccount'

type EmailSignupProps = {
  submittedRecently: boolean
  textInputVariant?: TextInputVariant
  btnVariant?: BtnVariant
}

export default function EmailForm({
  submittedRecently,
  textInputVariant = 'primary',
  btnVariant = 'primary',
}: EmailSignupProps) {
  const { account, isLoggedIn, isWalletConnected } = useAccount()
  const [emailInput, emailInputSet] = useState('')
  const openAuthFlow = useOpenAuthFlow()
  const [waitingForAuth, waitingForAuthSet] = useState(false)

  const [{ status, error }, submitEmailForm] = useAsync(async () => {
    if (!isWalletConnected) {
      createToast('Please connect your wallet to continue')
      return
    }

    if (!isLoggedIn) {
      openAuthFlow()
      waitingForAuthSet(true)
      return
    }

    const serverResp = await sendEmail(emailInput)
    if (serverResp.error === true && serverResp.message !== undefined) {
      const error = new Error(serverResp.message)
      error.name = '' // remove Error: prefix
      throw error
    }
  })

  const onSubmitEmail = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      submitEmailForm()
    },
    [submitEmailForm],
  )

  useEffect(() => {
    if (waitingForAuth && isLoggedIn) {
      submitEmailForm()
      waitingForAuthSet(false)
    }
  }, [isLoggedIn, submitEmailForm, waitingForAuth])

  const submitLabel =
    submittedRecently && account?.email === emailInput
      ? 'Submitted'
      : 'Notify me'
  const errorStr = error?.toString()

  return (
    <div className="flex flex-col gap-y-4">
      <form
        className="w-full flex flex-col md:flex-row gap-x-4 gap-y-4"
        onSubmit={onSubmitEmail}
      >
        <TextInput
          className="flex flex-1 py-3"
          value={emailInput}
          onChange={emailInputSet}
          type="email"
          placeholder="Enter your email address"
          variant={textInputVariant}
        />
        <Button
          type="submit"
          pending={status === 'loading'}
          disabled={status === 'loading'}
          label={submitLabel}
          variant={btnVariant}
        />
      </form>
      {errorStr !== undefined && (
        <div
          className={classNames(
            'bg-error-background/10',
            'text-error-text',
            'text-sm rounded-lg',
            'px-3 py-1',
          )}
        >
          {errorStr}
        </div>
      )}
    </div>
  )
}
