import React, { ReactNode, useCallback } from 'react'
import type { Toast } from 'react-hot-toast'
import { twMerge } from 'tailwind-merge'
import CloseIcon from 'components/icons/CloseIcon'
import classNames from 'utils/classnames'
import { toastBackground } from 'utils/theme'
import { toastLibrary } from '.'

type Props = {
  toast: Toast
  fixedWidth?: boolean
  showCloseButton?: boolean
  dismissOnClick?: boolean
  onDismiss?: () => void
  children?: ReactNode
  className?: string
}

function CustomToast({
  toast,
  fixedWidth = false,
  showCloseButton = false,
  dismissOnClick = false,
  children,
  className,
  onDismiss,
}: Props) {
  const handleDismiss = useCallback(async () => {
    const { toast: toastFunc } = await toastLibrary()
    toastFunc.dismiss(toast.id)
    onDismiss?.()
  }, [toast.id, onDismiss])

  const handleClick = useCallback(
    () => dismissOnClick && handleDismiss(),
    [dismissOnClick, handleDismiss],
  )

  return (
    <div
      onClick={handleClick}
      className={twMerge(
        classNames(
          toast.visible ? 'animate-toast-enter' : 'animate-toast-exit',
          toastBackground,
          'max-w-toast shadow-lg rounded-lg',
          'ring-1 ring-neutral-700 dark:ring-transparent',
          'pointer-events-auto flex',
          'whitespace-pre-line',
          'p-6',
          'text-base leading-5 font-bold',
          fixedWidth && 'w-full',
        ),
        className,
      )}
    >
      <div className="w-full break-words">
        {showCloseButton && (
          <button
            aria-label="Close"
            onClick={handleDismiss}
            className={classNames(
              'fixed top-0 right-0 z-10',
              'flex items-center justify-center',
              'w-toast-dismiss h-toast-dismiss',
            )}
          >
            <CloseIcon className="w-5 h-5" />
          </button>
        )}
        {children}
      </div>
    </div>
  )
}

export default React.memo(CustomToast)
