import { SVGProps } from 'react'

export default function Wallet(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.875 5c-.345 0-.625.258-.625.577 0 .319-.28.577-.625.577S2 6.412 2 6.73v11.538c0 .319.28.577.625.577s.625.259.625.577c0 .319.28.577.625.577h16.25c.345 0 .625-.258.625-.577 0-.318.28-.577.625-.577s.625-.258.625-.577V6.731c0-.319-.28-.577-.625-.577s-.625-.258-.625-.577c0-.319-.28-.577-.625-.577H3.875zm16.25 2.308c.345 0 .625.258.625.577 0 .318-.28.577-.625.577H3.875c-.345 0-.625-.259-.625-.577 0-.319.28-.577.625-.577h16.25zm0 3.461c.345 0 .625.258.625.577 0 .319-.28.577-.625.577h-5c-.345 0-.625.258-.625.577 0 .319-.28.577-.625.577s-.625.258-.625.577c0 .319-.28.577-.625.577h-1.25c-.345 0-.625-.258-.625-.577 0-.319-.28-.577-.625-.577S9.5 12.819 9.5 12.5c0-.319-.28-.577-.625-.577h-5c-.345 0-.625-.258-.625-.577 0-.319.28-.577.625-.577h6.25c.345 0 .625.258.625.577 0 .319.28.577.625.577h1.25c.345 0 .625-.258.625-.577 0-.319.28-.577.625-.577h6.25z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
