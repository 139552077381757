// convenience function for using our predefined fetcher
import { MutatorOptions, mutate } from 'swr'
import { Overwrite } from 'utility-types'
import { fetcher } from './api'

// this allows us to return undefined from the optimisticData callback
type OptimisticData<Data> = Data | ((currentData?: Data) => Data | undefined)

// convenience function for using our predefined fetcher
// for GET requests when we are not in a react context
export function mutateRead<T>(
  key: string,
  options?:
    | boolean
    | Overwrite<MutatorOptions<T>, { optimisticData?: OptimisticData<T> }>,
): Promise<T | undefined> {
  return mutate<T>(key, fetcher(key), options)
}
