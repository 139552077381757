import { useEffect } from 'react'
import { usePlausible } from 'next-plausible'
import { useRouter } from 'next/router'

type Events = {
  pageview: never
}

const routeListener = (plausible: ReturnType<typeof usePlausible<Events>>) => {
  if (typeof window === 'undefined') return

  plausible('pageview')

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug('page', location.href)
  }
}

export const useRouteTracking = () => {
  const router = useRouter()
  const plausible = usePlausible<Events>()

  useEffect(() => {
    const handleRouteChange = () => {
      routeListener(plausible)
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [plausible, router.events])

  // track initial page load
  useEffect(() => {
    routeListener(plausible)
  }, [plausible])
}
