import { memo, useCallback, useEffect, useMemo } from 'react'
import { useTheme } from 'next-themes'
import { useDisconnect } from 'wagmi'
import { slugsAreEqual, useOffersForProfileSlug } from 'utils/api'
import { logout } from 'utils/api/auth/utils'
import { defaultChain } from 'utils/chains'
import { lazy } from 'utils/lazy'
import { useAccount } from 'utils/useAccount'
import { useOpenConnectModal } from 'utils/web3/walletconnect'
import { MismatchInfo } from './AvatarWithDecoration'
import ProfileNavButton from './ProfileNavButton'

// States:
// disconnected, no auth -> connect wallet button
// disconnected, auth'd -> says connect wallet, shows avatar, opens dropdown with "connect wallet" and "log out"
// connected, no auth -> shows avatar and name, opens dropdown with authenticate and disconnect options
// connected, auth'd -> avatar name, profile log out options
// connected, auth wallet mismatch -> avatar name of wallet, shows badge with "wrong wallet" hover, buttons are profile and log out

const ProfileDropdown = lazy({
  loader: () => import('./ProfileDropdown'),
  Fallback: ({
    props: {
      avatarAddress,
      open,
      handleButtonClick,
      mismatchInfo,
      isWalletConnected,
      offerCount,
    },
  }) => (
    <ProfileNavButton
      open={open}
      handleButtonClick={handleButtonClick}
      avatarAddress={avatarAddress}
      mismatchInfo={mismatchInfo}
      isWalletConnected={isWalletConnected}
      offerCount={offerCount}
    />
  ),
})

type Props = {
  open: boolean
  openSet: (openState: boolean) => void
  handleButtonClick: () => void
}

function ProfileButton({ open, openSet, handleButtonClick }: Props) {
  const {
    account,
    address,
    displayName,
    displaySlug,
    isLoggedIn,
    isWalletConnected,
  } = useAccount()
  const { openConnectModal } = useOpenConnectModal()

  // ensure the popover is closed if we shouldn't see it
  useEffect(() => {
    if (!isLoggedIn && !isWalletConnected && open) {
      openSet(false)
    }
  }, [isLoggedIn, isWalletConnected, open, openSet])

  const handleConnectWallet = useCallback(() => {
    openSet(false)
    openConnectModal()
  }, [openConnectModal, openSet])

  const { disconnect } = useDisconnect()

  const handleDisconnect = useCallback(() => {
    disconnect()
    openSet(false)
  }, [disconnect, openSet])

  const handleLogOut = useCallback(async () => {
    await logout()
    openSet(false)
  }, [openSet])

  const handleLinkClick = useCallback(() => {
    openSet(false)
  }, [openSet])

  const avatarAddress = address ?? account?.address

  const mismatchInfo = useMemo<MismatchInfo | undefined>(() => {
    if (!account || address === undefined) {
      return undefined
    }

    if (slugsAreEqual(account.address, address)) {
      return undefined
    }

    return {
      address,
      account,
    }
  }, [account, address])

  // always use ETH offers in the nav until we can support showing all chain offers
  const offerCount = useOffersForProfileSlug(address, defaultChain.id)

  const { theme, setTheme } = useTheme()
  const handleToggleTheme = useCallback(() => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
    openSet(false)
  }, [setTheme, theme, openSet])

  return (
    <ProfileDropdown
      open={open}
      openSet={openSet}
      handleButtonClick={handleButtonClick}
      avatarAddress={avatarAddress}
      mismatchInfo={mismatchInfo}
      isWalletConnected={isWalletConnected}
      isLoggedIn={isLoggedIn}
      displayName={displayName}
      displaySlug={displaySlug}
      handleLinkClick={handleLinkClick}
      handleDisconnect={handleDisconnect}
      handleConnectWallet={handleConnectWallet}
      handleLogOut={handleLogOut}
      theme={theme ?? 'dark'}
      handleToggleTheme={handleToggleTheme}
      offerCount={offerCount}
    />
  )
}

export default memo(ProfileButton)
